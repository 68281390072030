// validation.js

// Validate person name
export const validatePersonName = (name) => {
    const regex = /^[a-zA-Z\s]{1,100}$/;
    return regex.test(name);
  };
  
  // Validate entity name
  export const validateEntityName = (name) => {
    const regex = /^[a-zA-Z0-9\s_\-\.#@:()]{1,100}$/;
    return regex.test(name);
};
  
export const validateEmail = (email) => {
  
  if (!email || email.length > 254) return false; // Email length limit
  
  const regex = /^[a-zA-Z0-9](?:[a-zA-Z0-9._-]{0,98}[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,98}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/;
  
  return regex.test(email);
};


  
  // Validate phone number
  export const validatePhoneNumber = (phoneNumber) => {

    const phoneRegex = /^(?:(?:\+|00)([1-9]\d{0,3}))?[-. ]?(?:\(([0-9]{1,3})\)[-. ]?)?([0-9]{1,4})[-. ]?([0-9]{1,4})[-. ]?([0-9]{1,4})$/;

    if (!phoneNumber) return false;
    
    // Remove all spaces and non-numeric characters except + and ()
    const cleanNumber = phoneNumber.trim();
    
    // Test the number against our regex
    const isValid = phoneRegex.test(cleanNumber);
    
    // Additional validation for minimum and maximum lengths
    const digitCount = cleanNumber.replace(/\D/g, '').length;
    const isValidLength = digitCount >= 7 && digitCount <= 15;
    
    return isValid && isValidLength;
  };


  // Validate password
export const validatePassword = (password) => {
    // Check length
    if (password.length < 12 || password.length > 128) return false;
  
    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) return false;
  
    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) return false;
  
    // Check for at least one digit
    if (!/\d/.test(password)) return false;
  
    // Check for at least one special character
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) return false;
  
    // If all checks pass, return true
    return true;
  };

 

// Validate URL
export const validateURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  
  // Validate host (URL, hostname, or IP address)
  export const validateHost = (host) => {
    // URL validation
    if (validateURL(host)) return true;
  
    // Hostname validation
    const hostnameRegex = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
    if (hostnameRegex.test(host)) return true;
  
    // IPv4 validation
    const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;
    if (ipv4Regex.test(host)) {
      const parts = host.split('.');
      return parts.every(part => parseInt(part) >= 0 && parseInt(part) <= 255);
    }
  
    // IPv6 validation
    const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;
    return ipv6Regex.test(host);
  };