import React, { useState, useEffect, useRef } from "react";
import { CButton, CToast, CToastBody } from '@coreui/react';
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CSSProperties } from "react";
import { ShimmerTable } from "react-shimmer-effects";
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";

const Applications = () => {
  
  const navigate = useNavigate();
  
  // State management
  const [applications, setApplications] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [user, setUser] = useState({});

  // Pagination and filter states
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [filterList, setFilterList] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    types: [],
    riskRatings: [],
    businessImpacts: [],
    businessUnits: [],
    assetGroups: [],
    businessOwners: []
  });

  const customStyles = {
    content: {
      top: '30%',
      left: '25%',
      width: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '25%',
      backgroundColor: '#ffffff',
      borderRadius: 15,
      borderColor: 'ffffff'
    },
  };

  // References
  const toaster = useRef();
  const prevSearchInput = useRef(searchInput);

  const fetchApplications = async (isFirstTime, page, rowsPerPage, searchText, filterList) => {
    if (isFirstTime) {
      setOnLoading(true);
    }

    try {
      const queryParams = new URLSearchParams({
        page,
        rowsPerPage
      });
      
      if (searchText) {
        queryParams.append('search', searchText);
      }
      
      if (Object.keys(filterList).length > 0) {
        queryParams.append('filterList', JSON.stringify(filterList));
      }

      const token = localStorage.getItem('ASIToken');
      const response = await axios.get(`/api/v1/applications?${queryParams.toString()}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      //setApplications(response.data.applications);

      setApplications(
        response.data.applications.map((app) => ({
          ...app,
          businessUnit1: app.businessUnit?.name || '',
          assetGroup1: app.assetGroup?.name || '',
          businessOwner1: `${app.businessOwner?.firstName || ''} ${app.businessOwner?.lastName || ''}`.trim(),
        }))
      );
      


      setCount(response.data.totalCount);
      setFilterOptions(response.data.filterOptions);
    } catch (error) {
      console.error('Error fetching applications:', error);
      toast.error('Failed to fetch applications');
    } finally {
      setOnLoading(false);
    }
  };

  const deleteApplication = async (id) => {
    const bearerToken = localStorage.getItem('ASIToken');
  
    try {
      const response = await axios.delete(`/api/v1/applications/${id}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
  
      if (response.data.hasOwnProperty('error')) {
        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast('Application deleted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
  
        fetchApplications(true, page, rowsPerPage, searchText, filterList);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to delete application. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Navigation handlers
  const goToApplication = (id) => {
    navigate('/application?applicationId=' + id);
  };

  const goToEditApplication = (id) => {
    navigate('/edit-application?applicationId=' + id);
  };

  const goToAddApplication = () => {
    navigate('/add-application');
  };

  // Modal handlers
  const handleClick = (application) => {
    setApplicationToDelete(application);
    setModalIsOpen(true);
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      deleteApplication(applicationToDelete);
    }
    setModalIsOpen(false);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // User details
  const getUserDetails = () => {
    setUser(JSON.parse(localStorage.getItem('ASIUser')));
  };

  // Effects
  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    fetchApplications(true, 0, rowsPerPage, '', {});
  }, []);

  useEffect(() => {
    if (prevSearchInput.current && searchInput.length === 0) {
      fetchApplications(true, 0, rowsPerPage, '', filterList);
    }
    prevSearchInput.current = searchInput;
  }, [searchInput]);

  const columns = [
    {
      name: "index",
      label: "#",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return ((page * rowsPerPage) + tableMeta.rowIndex + 1);
        }
      }
    },
    {
      name: "name",
      label: "Application Name",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          names: filterOptions.types,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Type'] || []
      }
    },
    {
      name: "riskRating",
      label: "Risk Rating",
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          names: filterOptions.riskRatings,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Risk Rating'] || [],
        customBodyRender: (value) => {
          let bgColor;
          let textColor;

          switch(value) {
            case 'CRITICAL':
              bgColor = '#FF0000';
              textColor = '#fff';
              break;
            case 'HIGH':
              bgColor = '#A6001B';
              textColor = '#fff';
              break;
            case 'MEDIUM':
              bgColor = '#FFC300';
              textColor = '#000';
              break;
            case 'LOW':
              bgColor = '#B3FFB3';
              textColor = '#000';
              break;
            case 'Best Practice':
              bgColor = '#fdfe25';
              textColor = '#000';
              break;
            default:
              return '---';
          }

          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <text style={{
                padding: 5,
                backgroundColor: bgColor,
                color: textColor,
                width: 120,
                textAlign: 'center',
                borderRadius: 10,
                fontSize: 12
              }}>
                {value}
              </text>
            </div>
          );
        }
      }
    },
    {
      name: "businessImpact",
      label: "Business Impact",
      options: {
        filter: true,
        sort: true,
        filterOptions: {
          names: filterOptions.businessImpacts,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Business Impact'] || []
      }
    },
    {
      name: "businessUnit",
      label: "Business Unit",
      options: {
        filter: true,
        sort: true,
        download:false,
        filterOptions: {
          names: filterOptions.businessUnits,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Business Unit'] || [],
        customBodyRender: (value) => value?.name || '---'
      }
    },
    {
      name: "assetGroup",
      label: "Asset Group",
      options: {
        filter: true,
        download:false,
        sort: true,
        filterOptions: {
          names: filterOptions.assetGroups,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Asset Group'] || [],
        customBodyRender: (value) => value?.name || '---'
      }
    },
    {
      name: "businessOwner",
      label: "Business Owner",
      options: {
        filter: true,
        sort: true,
        download:false,
        filterOptions: {
          names: filterOptions.businessOwners,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Business Owner'] || [],
        customBodyRender: (value) => value ? `${value.firstName} ${value.lastName}` : '---'
      }
    },
    {
      name: "businessUnit1",
      label: "Business Unit",
      options: {
        filter: true,
        sort: true,
        download:true,
        display:false,
        filterOptions: {
          names: filterOptions.businessUnits,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Business Unit'] || [],
        customBodyRender: (value) => value?.name || '---'
      }
    },
    {
      name: "assetGroup1",
      label: "Asset Group",
      options: {
        filter: true,
        sort: true,
        download:true,
        display:false,
        filterOptions: {
          names: filterOptions.assetGroups,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Asset Group'] || [],
        customBodyRender: (value) => value?.name || '---'
      }
    },
    {
      name: "businessOwner1",
      label: "Business Owner",
      options: {
        filter: true,
        download:true,
        sort: true,
        display:false,
        filterOptions: {
          names: filterOptions.businessOwners,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Business Owner'] || [],
        customBodyRender: (value) => value ? `${value.firstName} ${value.lastName}` : '---'
      }
    },
    {
      name: "activeIssues",
      label: "Active Issues",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "testingStatus",
      label: "Testing Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{
              fontSize: '10px',
              fontWeight: 'normal',
              padding: '6px 12px',
              borderRadius: '16px',
              display: 'inline-block',
              textTransform: 'uppercase',
              textAlign:'center',
              backgroundColor: (() => {
                switch(value?.toUpperCase()) {
                  case 'YET TO START': return '#f1c40f';
                  case 'IN PROGRESS': return '#3498db';
                  case 'COMPLETED': return '#2ecc71';
                  case 'UNDER REVIEW': return '#e67e22';
                  default: return '#95a5a6';
                }
              })(),
              color: value?.toUpperCase() === 'YET TO START' ? '#000000' : '#ffffff'
            }}>
              {value || '---'}
            </span>
          </div>
        )
      }
    },
    {
      name: "_id",
      label: "View",
      options: {
        filter: false,
        download:false,
        customBodyRender: (value) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <CButton 
                color="primary" 
                variant="outline"
                onClick={() => goToApplication(value)}
                className="m-2" 
                style={{ 
                  width: '100%', 
                  fontSize: 12, 
                  fontWeight: 'bold', 
                  color: '#fff', 
                  borderColor:'#fff',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}
              >
                <FaEye size={15} style={{ color: 'white', marginRight:15 }} />
                <span>View</span>
              </CButton>
            </div>
          );
        }
      }
    },
    {
      name: "_id",
      label: "Actions",
      options: {
        filter: false,
        download:false,
        customBodyRender: (value) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: '8px'
            }}>
              {(user.role === "Administrator" || user.role === "Application Manager") && (
                <>
                  <CButton 
                    color="info"
                    onClick={() => goToEditApplication(value)}
                    variant="outline"
                    className="m-1"
                    style={{ 
                      width: '40px', 
                      fontSize: 12, 
                      fontWeight: 'bold', 
                      color: 'blue', 
                      borderColor: 'blue',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <CiEdit size={15} style={{ color: 'blue' }} />
                  </CButton>
                  <CButton 
                    color="danger"
                    onClick={() => handleClick(value)}
                    variant="outline"
                    className="m-1"
                    style={{ 
                      width: '40px', 
                      fontSize: 12, 
                      fontWeight: 'bold', 
                      color: 'red', 
                      borderColor: 'red',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <MdDeleteOutline size={15} style={{ color: 'red' }} />
                  </CButton>
                </>
              )}
            </div>
          );
        }
      }
    }
  ];

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
    }
  });

  const options = {
    filterType: "multiselect",
    responsive: "stacked",
    elevation: 0,
    filter: true,
    download: true,
    downloadOptions: {
      filename: 'Applications.csv', 
      separator: ',', 
    },
    print: false,
    search: true,
    searchOpen: true,
    viewColumns: true,
    selectableRows: false,
    rowsPerPageOptions: [10, 20, 60, 100, 150],
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: '10px',
          padding: '8px 16px'
        }}>
          <input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSearchText(searchInput);
                setPage(0);
                fetchApplications(true, 0, rowsPerPage, searchInput, filterList);
              }
            }}
            placeholder="Search applications..."
            style={{
              padding: '8px 12px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              width: '300px',
              fontSize: '14px',
              color: '#000',
            }}
          />
          <button
            onClick={() => {
              setSearchText(searchInput);
              setPage(0);
              fetchApplications(true, 0, rowsPerPage, searchInput, filterList);
            }}
            style={{
              padding: '8px 16px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#1976d2',
              color: 'white',
              cursor: 'pointer',
              fontSize: '14px'
            }}
          >
            Search
          </button>
          {searchInput && (
            <button
              onClick={() => {
                setSearchInput('');
                setSearchText('');
                setPage(0);
                fetchApplications(true, 0, rowsPerPage, '', filterList);
              }}
              style={{
                padding: '8px 16px',
                borderRadius: '4px',
                border: 'none',
                backgroundColor: '#d32f2f',
                color: 'white',
                cursor: 'pointer',
                fontSize: '14px'
              }}
            >
              Clear
            </button>
          )}
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPage(tableState.page);
          fetchApplications(
            true,
            tableState.page,
            tableState.rowsPerPage,
            searchText,
            filterList
          );
          break;

        case 'changeRowsPerPage':
          setRowsPerPage(tableState.rowsPerPage);
          setPage(0);
          fetchApplications(
            true,
            0,
            tableState.rowsPerPage,
            searchText,
            filterList
          );
          break;

        case 'filterChange':
          const newFilterList = {};
          tableState.filterList.forEach((filter, index) => {
            if (filter && filter.length) {
              newFilterList[columns[index].label] = filter;
            }
          });
          setFilterList(newFilterList);
          setPage(0);
          fetchApplications(
            true,
            0,
            tableState.rowsPerPage,
            searchText,
            newFilterList
          );
          break;

        case 'resetFilters':
          setFilterList({});
          setPage(0);
          fetchApplications(
            true,
            0,
            tableState.rowsPerPage,
            searchText,
            {}
          );
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: onLoading ? 'Loading data...' : 'No applications found',
      }
    }
  };

  return (
    <div className="activeScans">
      {/* Delete Confirmation Modal */}
      {setModalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Delete Confirmation"
        >
          <text style={{ color: '#000', fontSize: 18 }}>
            Are you sure you want to permanently delete this application?
          </text>
          <br/><br/>
          <button 
            onClick={() => handleConfirmation(true)} 
            style={{ 
              width: 70, 
              borderWidth: 0, 
              borderRadius: 5, 
              backgroundColor: 'green', 
              color: 'white', 
              padding: 10 
            }}
          >
            Yes
          </button>
          <button 
            onClick={() => handleConfirmation(false)} 
            style={{ 
              marginLeft: 30, 
              borderWidth: 0, 
              borderRadius: 5, 
              width: 70, 
              backgroundColor: 'red', 
              color: 'white', 
              padding: 10 
            }}
          >
            No
          </button>
        </Modal>
      )}

      <div style={{ width: '100%' }}>
        <div>
          <div style={{ 
            marginBottom: '2rem', 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-between' 
          }}>
            <h4><strong>Applications</strong></h4>

            {(user.role === "Administrator" || user.role === "Application Manager") && (
              <CButton
                style={{
                  width: 200,
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 13,
                  fontStyle:'bold',
                  background: '#e50202'
                }}
                onClick={goToAddApplication}
                color="primary"
                className="px-3"
              >
                <AiOutlineAppstoreAdd size={24} style={{ color: 'white' }} />
                <span style={{marginLeft: 15}}>Add an Application</span>
              </CButton>
            )}
          </div>

          {onLoading && (
            <ShimmerTable row={8} col={10} />
          )}

          {!onLoading && (
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title=""
                data={applications}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          )}
        </div>
      </div>
    </div>
  );
};

export default Applications;