import React from 'react';

const GitLabIntegration = () => {
  return (
    <div>
      <h3 className="darkText">GitLab CI/CD Integration Instructions for SAST and SCA Scans</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitLab CI/CD Variables</h4>
      <ol>
        <li>Go to your GitLab project.</li>
        <li>Navigate to <strong>Settings</strong> {'->'} <strong>CI/CD</strong>.</li>
        <li>Expand the <strong>Variables</strong> section.</li>
        <li>Add the following variables:
          <ul>
            <li><strong>INTRUCEPT_CLIENT_ID</strong></li>
            <li><strong>INTRUCEPT_CLIENT_SECRET</strong></li>
            <li><strong>INTRUCEPT_APPLICATION_ID</strong></li>
          </ul>
        </li>
        <li>Make sure to mask the secret variables.</li>
      </ol>

      <h4 className="darkText">Step 2: Create GitLab CI/CD Configuration Files</h4>
      <p>Create the following files in your project repository:</p>

      <h5 className="darkText">1. .gitlab-ci.yml</h5>
      <pre>
        <code>
{`stages:
  - test

variables:
  INTRUCEPT_CLIENT_ID: $INTRUCEPT_CLIENT_ID
  INTRUCEPT_CLIENT_SECRET: $INTRUCEPT_CLIENT_SECRET
  INTRUCEPT_APPLICATION_ID: $INTRUCEPT_APPLICATION_ID

include:
  - local: sast.gitlab-ci.yml
  - local: sca.gitlab-ci.yml`}
        </code>
      </pre>

      <h5 className="darkText">2. sast.gitlab-ci.yml</h5>
      <pre>
        <code>
{`sast:
  stage: test
  image: alpine:latest
  script:
    - apk add --no-cache curl zip jq
    - zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"
    - |
      RESPONSE=$(curl -X POST \
        -H "Client-ID: $INTRUCEPT_CLIENT_ID" \
        -H "Client-Secret: $INTRUCEPT_CLIENT_SECRET" \
        -F "projectZipFile=@project.zip" \
        -F "applicationId=$INTRUCEPT_APPLICATION_ID" \
        -F "scanName=SAST Scan - $CI_COMMIT_SHORT_SHA" \
        -F "language=csharp" \
        https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans)        
      

      # Check for error in response.
      ERROR=$(echo "$RESPONSE" | jq -r '.error // empty')
      if [ ! -z "$ERROR" ]; then
        echo "Error: $ERROR"
        exit 1
      else
        VULNS_TABLE=$(echo "$RESPONSE" | jq -r '.vulnsTable')
        echo "Vulnerabilities Table:"
        echo "$VULNS_TABLE"
      fi
`}
        </code>
      </pre>

      <h5 className="darkText">3. sca.gitlab-ci.yml</h5>
      <pre>
        <code>
{`sca:
  stage: test
  image: alpine:latest
  script:
    - apk add --no-cache curl zip jq
    - zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"
    - |
      RESPONSE=$(curl -X POST \
        -H "Client-ID: $INTRUCEPT_CLIENT_ID" \
        -H "Client-Secret: $INTRUCEPT_CLIENT_SECRET" \
        -F "projectZipFile=@project.zip" \
        -F "applicationId=$INTRUCEPT_APPLICATION_ID" \
        -F "scanName=SCA Scan - $CI_COMMIT_SHORT_SHA" \
        -F "language=csharp" \
        https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans)         
      
      
      # Check for error in response
      ERROR=$(echo "$RESPONSE" | jq -r '.error // empty')
      if [ ! -z "$ERROR" ]; then
        echo "Error: $ERROR"
        exit 1
      else
        VULNS_TABLE=$(echo "$RESPONSE" | jq -r '.vulnsTable')
        echo "Vulnerabilities Table:"
        echo "$VULNS_TABLE"
      fi
`}
        </code>
      </pre>

      <h4 className="darkText">Explanation of the GitLab CI/CD Configuration</h4>
      <p>This GitLab CI/CD configuration does the following:</p>
      <ol>
        <li>Defines a 'test' stage for running SAST and SCA scans.</li>
        <li>Uses Alpine Linux as the base image for lightweight containers.</li>
        <li>Creates a zip file of the project for scanning, 
          excluding unnecessary files and directories.</li>
        <li>Performs SAST scan using the Intrucept integration API.</li>
        <li>Performs SCA scan using the Intrucept integration API.</li>
        <li>Displays the vulnerabilities tables from both scan results.</li>
        <li>Continues with the pipeline.</li>
      </ol>

      <p><strong>Note:</strong> Ensure that you have added the required variables 
      (INTRUCEPT_CLIENT_ID, INTRUCEPT_CLIENT_SECRET, INTRUCEPT_APPLICATION_ID) to 
      your GitLab CI/CD variables before running this pipeline.</p>
    </div>
  );
};

export default GitLabIntegration;