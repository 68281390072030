import React from 'react';

const GradleIntegration = () => {
  return (
    <div>
      <h3 className="darkText">GitHub Actions Integration Instructions for Gradle with SAST and SCA Scans</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitHub Secrets</h4>
      <ol>
        <li>Go to your GitHub repository.</li>
        <li>Click on <strong>Settings</strong>.</li>
        <li>Click on <strong>Secrets and variables</strong> {'->'} <strong>Actions</strong>.</li>
        <li>Click on <strong>New repository secret</strong>.</li>
        <li>Add secrets for <strong>INTRUCEPT_CLIENT_ID</strong>, <strong>INTRUCEPT_CLIENT_SECRET</strong>, and <strong>INTRUCEPT_APPLICATION_ID</strong>.</li>
      </ol>

      <h4 className="darkText">Step 2: Create GitHub Actions Workflow for SAST Check and Gradle Build</h4>
      <p>Create a file named <code>sast.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: SAST Check and Gradle Build
on:
  push:
    branches: [ master ]
  pull_request:
    branches: [ master ]
jobs:
  sast-and-build:
    runs-on: ubuntu-latest
    steps:
    - uses: actions/checkout@v3
    
    - name: List repository contents
      run: ls -R
    
    - name: Check Gradle Wrapper files
      run: |
        if [ -f "gradlew" ] && [ -f "gradlew.bat" ] && [ -d "gradle/wrapper" ]; then
          echo "Gradle Wrapper files found."
        else
          echo "Gradle Wrapper files are missing."
          echo "Contents of the current directory:-"
          ls -la
          echo "Contents of the gradle directory (if it exists):"
          [ -d "gradle" ] && ls -la gradle || echo "gradle directory not found"
          exit 1
        fi
    
    - name: Validate Gradle Wrapper
      uses: gradle/wrapper-validation-action@v1
    
    - name: Set up JDK 11
      uses: actions/setup-java@v3
      with:
        java-version: '11'
        distribution: 'temurin'
    
    - name: Cache Gradle packages
      uses: actions/cache@v3
      with:
        path: ~/.gradle/caches
        key: \${{ runner.os }}-gradle-\${{ hashFiles('**/*.gradle*', '**/gradle-wrapper.properties') }}
        restore-keys: |
          \${{ runner.os }}-gradle-
    
    - name: Ensure gradlew is executable
      run: chmod +x gradlew
    
    - name: Create project zip
      run: zip -r project.zip . -x "*.git*"
    
    - name: Install jq
      run: sudo apt-get update && sudo apt-get install -y jq
    
    - name: Perform SAST Scan
      id: sast
      run: |
        RESPONSE=$(curl -X POST \
          -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \
          -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \
          -F "projectZipFile=@project.zip" \
          -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \
          -F "scanName=SAST Scan - Gradle - from GitHub Actions" \
          https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans)
        
        
        
        ERROR=$(echo "$RESPONSE" | jq -r '.error // empty')
        if [ ! -z "$ERROR" ]; then
          echo "Error in SAST Scan: $ERROR"
          exit 1
        fi
        
        VULNS_TABLE=$(echo "$RESPONSE" | jq -r '.vulnsTable')
        echo "Vulnerabilities Table:"
        echo "$VULNS_TABLE"
    
    - name: Build with Gradle
      run: ./gradlew build
    
    - name: Upload artifact
      uses: actions/upload-artifact@v3
      with:
        name: project-files
        path: project.zip
    
    - name: Upload Gradle logs
      if: failure()
      uses: actions/upload-artifact@v3
      with:
        name: gradle-logs
        path: build/reports
`}
        </code>
      </pre>

      <h4 className="darkText">Step 3: Create GitHub Actions Workflow for SCA Check and Gradle Build</h4>
      <p>Create a file named <code>sca.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: SCA Check and Gradle Build
on:
  push:
    branches: [ master ]
  pull_request:
    branches: [ master ]
jobs:
  sca-and-build:
    runs-on: ubuntu-latest
    steps:
    - uses: actions/checkout@v3
    
    - name: List repository contents
      run: ls -R
    
    - name: Check Gradle Wrapper files
      run: |
        if [ -f "gradlew" ] && [ -f "gradlew.bat" ] && [ -d "gradle/wrapper" ]; then
          echo "Gradle Wrapper files found."
        else
          echo "Gradle Wrapper files are missing."
          echo "Contents of the current directory:"
          ls -la
          echo "Contents of the gradle directory (if it exists):"
          [ -d "gradle" ] && ls -la gradle || echo "gradle directory not found"
          exit 1
        fi
    
    - name: Validate Gradle Wrapper
      uses: gradle/wrapper-validation-action@v1
    
    - name: Set up JDK 11
      uses: actions/setup-java@v3
      with:
        java-version: '11'
        distribution: 'temurin'
    
    - name: Cache Gradle packages
      uses: actions/cache@v3
      with:
        path: ~/.gradle/caches
        key: \${{ runner.os }}-gradle-\${{ hashFiles('**/*.gradle*', '**/gradle-wrapper.properties') }}
        restore-keys: |
          \${{ runner.os }}-gradle-
    
    - name: Ensure gradlew is executable
      run: chmod +x gradlew
    
    - name: Create project zip
      run: zip -r project.zip . -x "*.git*"
    
    - name: Install jq
      run: sudo apt-get update && sudo apt-get install -y jq
    
    - name: Perform SCA Scan
      id: sca
      run: |
        RESPONSE=$(curl -X POST \
          -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \
          -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \
          -F "projectZipFile=@project.zip" \
          -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \
          -F "scanName=SCA Scan - Gradle - from GitHub Actions" \
          https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans)
        
        
        
        ERROR=$(echo "$RESPONSE" | jq -r '.error // empty')
        if [ ! -z "$ERROR" ]; then
          echo "Error in SCA Scan: $ERROR"
          exit 1
        fi
        
        VULNS_TABLE=$(echo "$RESPONSE" | jq -r '.vulnsTable')
        echo "Vulnerabilities Table:"
        echo "$VULNS_TABLE"
    
    - name: Build with Gradle
      run: ./gradlew build
    
    - name: Upload artifact
      uses: actions/upload-artifact@v3
      with:
        name: project-files
        path: project.zip
    
    - name: Upload Gradle logs
      if: failure()
      uses: actions/upload-artifact@v3
      with:
        name: gradle-logs
        path: build/reports
`}
        </code>
      </pre>

      <h4 className="darkText">Explanation of the Workflows</h4>
      <p>These GitHub Actions workflows for Gradle build with SAST and SCA scanning do the following:</p>
      <ol>
        <li>Trigger on push or pull request to the main branch.</li>
        <li>Check and validate the Gradle Wrapper files.</li>
        <li>Set up the Java JDK environment.</li>
        <li>Cache Gradle packages for faster builds.</li>
        <li>Build the project using Gradle.</li>
        <li>Create a zip file of the project for scanning.</li>
        <li>Perform the respective scan (SAST or SCA) using the Intrucept API.</li>
        <li>Display the vulnerabilities table from the scan results.</li>
        <li>Check the scan result and fail the workflow if issues are found.</li>
        <li>Upload the build artifacts if the scan passes.</li>
      </ol>

      <p><strong>Note:</strong> Remember to adjust the Java version and branch names to match your project's specific requirements. Ensure that you have added the required secrets (INTRUCEPT_CLIENT_ID, INTRUCEPT_CLIENT_SECRET, INTRUCEPT_APPLICATION_ID) to your GitHub repository before running these workflows.</p>
    </div>
  );
};

export default GradleIntegration;