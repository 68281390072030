import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect
} from '@coreui/react'

import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoCodescan } from "react-icons/go";
import axios from 'axios';
import { validateEntityName } from '../../../../helpers/validation';

const EditTicket = () => {
  const navigate = useNavigate()
  const location = useLocation();

  const [title, setTitle] = useState("")
  const [category, setCategory] = useState("User Activity")
  const [priority, setPriority] = useState("CRITICAL")
  const [note, setNote] = useState("")
  const [description, setDescription] = useState("")
  const [assignedTo, setAssignedTo] = useState('')  
  const [theStatus, setTheStatus] = useState('');
  const [attachments, setAttachments] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [ticketId, setTicketId] = useState('')
  const [ticket, setTicket] = useState({})

  const [errors, setErrors] = useState({
    title: '',
    description: '',
    note: '',
    assignedTo: '',
    attachments: ''
  });

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const toaster = useRef();

  const encodeScriptContent = (content) => {
    return content.replace(/<script|<\/script|javascript:|on\w+=/gi, match => {
      return match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    });
  };

  function isExceeding100MB(file) {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    return fileSizeInMB > 100;
  }

  useEffect(() => {    
    fetchUsers();   
    window.scrollTo(0, 0);
    var arr = location.search.split('=');
    var theUserId = arr[1];
    setTicketId(theUserId);
    loadTicketDetails(theUserId);
  }, []);

  const [user, setUser] = useState({});
  
  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    setUser(JSON.parse(localStorage.getItem('ASIUser')));
  }

  const loadTicketDetails = async (theTicketId) => {
    setLoading(true);
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/organizations/tickets/${theTicketId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setTicket(response.data.ticket);
    setTitle(response.data.ticket.title)
    setCategory(response.data.ticket.category)
    setPriority(response.data.ticket.priority)
    setDescription(response.data.ticket.description)
    setNote(response.data.ticket.note?response.data.ticket.note:'')
    setAssignedTo(response.data.ticket.assignedTo?response.data.ticket.assignedTo._id:null)
    setTheStatus(response.data.ticket.status)    
    setLoading(false);
  };

  console.log('assignedTo:', assignedTo)


  const fetchUsers = async () => {
    setLoading(true);
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('/api/v1/organizations/users/all/active', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setUsers(response.data.users);
    
    setLoading(false);
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {
      title: '',
      description: '',
      note: '',
      assignedTo: '',
      attachments: ''
    };

    if (!validateEntityName(title)) {
      newErrors.title = 'Invalid title. Only letters, numbers, spaces, underscores, dots, #, @ and hyphens are allowed (max 100 characters).';
      isValid = false;
    }

    if (description.length > 10000) {
      newErrors.description = 'Description must not exceed 10,000 characters.';
      isValid = false;
    }

    if (note.length > 10000) {
      newErrors.note = 'Note must not exceed 10,000 characters.';
      isValid = false;
    }

    if (!assignedTo) {
      newErrors.assignedTo = 'Please assign the ticket to a user.';
      isValid = false;
    }

    for (let i = 0; i < attachments.length; i++) {
      if (isExceeding100MB(attachments[i])) {
        newErrors.attachments = 'One or more files exceed the 100MB limit.';
        isValid = false;
        break;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const updateTicket = () => {
    if (validateForm()) {
      setLoading(true)      
      const bearerToken = localStorage.getItem('ASIToken');
      const body = {
        id: ticketId,
        title: title,
        assignedTo: assignedTo,
        description: encodeScriptContent(description),
        status: theStatus,
        category: category,
        priority: priority,
        note: encodeScriptContent(note)
      }
      const headers = new Headers();
      fetch(`${global.backendUrl}/api/v1/organizations/tickets`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(data => {
        if(data.hasOwnProperty('error')){
          setErrors(prevErrors => ({ ...prevErrors, general: data.error }));
          setLoading(false);
        }
        else if(data.hasOwnProperty('err')){
          setLoading(false);
          setErrors(prevErrors => ({ ...prevErrors, general: "Something went wrong. Please try again" }));
        } else {
          setSubmissionSuccess(true);
          setLoading(false);
          toast('Ticket updated', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate('/tickets')
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        setErrors(prevErrors => ({ ...prevErrors, general: "An error occurred. Please try again." }));
      });
    }
  }

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setAttachments(selectedFiles);
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate('/tickets')
  }

  if (loading) {
    return (
      <div className="loading-spinner" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #e50202',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  return (
    <div style={{ display:'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4>Editing ticket</h4>
            <CButton
              onClick={goBack}
              style={{
                width: 200,
                marginBottom: '2%',
                marginRight:20,
                borderWidth: 0,
                fontSize: 13,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight:10 }} />
              Back to Tickets
            </CButton>
          </div>

          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white'  }}>Ticket Title</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Ticket Title"
                autoComplete="title"
                className="white-input"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                maxLength={100}
                style={{ width: '100%' }}
              />
              {errors.title && <div style={{ color: 'red', marginTop: '5px' }}>{errors.title}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white'  }}>Ticket Category</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="scanType"
                className="white-input"
                onChange={(e) => setCategory(e.target.value)}
                value={category}
                style={{ width: '100%' }}
              >
                <option value="User Activity">User Activity</option>
                <option value="Support">Support</option>
                <option value="Network">Network</option>
                <option value="Vulnerability Management">Vulnerability Management</option>
                <option value="Endpoint">Endpoint</option>
                <option value="Threat Management">Threat Management</option>
                <option value="Compliance">Compliance</option>
                <option value="Phishing">Phishing</option>
                <option value="VPN">VPN</option>
                <option value="Suspicious activity">Suspicious activity</option>
              </CFormSelect>
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white'  }}>Priority</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="scanType"
                className="white-input"
                onChange={(e) => setPriority(e.target.value)}
                value={priority}
                style={{ width: '100%' }}
              >
                <option value="CRITICAL">Critical</option>
                <option value="HIGH">High</option>
                <option value="MEDIUM">Medium</option>
                <option value="LOW">Low</option>
              </CFormSelect>
            </CInputGroup>

            <CFormLabel htmlFor="formTextarea" style={{ marginTop: 30, color: 'white' }}>Description</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <textarea
                id="formTextarea"
                placeholder="Description"
                autoComplete="description"
                className="form-control white-input"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                style={{ width: '100%', resize: 'vertical', minHeight: '100px' }}
                maxLength={10000}
              />
              {errors.description && <div style={{ color: 'red', marginTop: '5px' }}>{errors.description}</div>}
              <div style={{ color: 'white', marginTop: '5px' }}>{description.length}/10000 characters</div>
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Assigned To</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="assignedTo"
                className="white-input"
                onChange={(e) => setAssignedTo(e.target.value)}
                value={assignedTo}
                disabled={user.role === "Tester"}
                style={{ width: '100%' }}
              >
                {users.map(user => (
                  <option key={user._id} value={user._id}>
                    {user.firstName} {user.lastName}  ({user.email})
                  </option>
                ))}
              </CFormSelect>
              {errors.assignedTo && <div style={{ color: 'red', marginTop: '5px' }}>{errors.assignedTo}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Status</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="theStatus"
                className="white-input"
                value={theStatus}
                onChange={(e) => setTheStatus(e.target.value)}
                style={{ width: '100%' }}
              >
                <option value="Open">OPEN</option>
                <option value="In Progress">IN PROGRESS</option>
                <option value="On Hold">ON HOLD</option>
                <option value="Resolved">RESOLVED</option>
              </CFormSelect>
            </CInputGroup>     

            <CFormLabel htmlFor="formTextarea" style={{ marginTop: 30, color: 'white' }}>Note</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <textarea
                id="formTextarea"
                placeholder="Note"
                autoComplete="note"
                className="form-control white-input"
                onChange={(e) => setNote(e.target.value)}
                value={note}
                style={{ width: '100%', resize: 'vertical', minHeight: '100px' }}
                maxLength={10000}
              />
              {errors.note && <div style={{ color: 'red', marginTop: '5px' }}>{errors.note}</div>}
              <div style={{ color: 'white', marginTop: '5px' }}>{note.length}/10000 characters</div>
            </CInputGroup>

            {errors.general && <div style={{ color: 'red', marginTop: '15px' }}>{errors.general}</div>}

            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 13,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={updateTicket}
              disabled={loading}
            >              
              {loading ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Update Ticket'
              }
            </CButton>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default EditTicket