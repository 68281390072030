import React from 'react';

const IntelliJIntegration = () => {
  return (
    <div className="intellij-integration-guide">
      <h2 className="darkText">IntelliJ IDEA Integration for SAST and SCA Scans</h2>
      <hr/>

      <section>
        <h3 className="darkText">Step 1: Install the Intrucept AppSecOps Plugin</h3>
        <ol>
          <li>Open IntelliJ IDEA.</li>
          <li>Go to <strong>File</strong> {'>'} <strong>Settings</strong> (on Windows/Linux) or <strong>IntelliJ IDEA</strong> {'>'} <strong>Preferences</strong> (on macOS).</li>
          <li>Select <strong>Plugins</strong> from the left sidebar.</li>
          <li>In the search bar, type "Intrucept AppSecOps".</li>
          <li>Locate the Intrucept AppSecOps plugin in the search results.</li>
          <li>Click the <strong>Install</strong> button next to the plugin.</li>
          <li>Once installation is complete, click <strong>Restart IDE</strong> to apply changes.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 2: Set Up intrucept-config.txt</h3>
        <ol>
          <li>In your project's root directory, create a new file named <code>intrucept-config.txt</code>.</li>
          <li>Open the file and add the following content:
            <pre>
              <code>
{`CLIENT_ID = your_intrucept_client_id
CLIENT_SECRET = your_intrucept_client_secret
APPLICATION_ID = your_intrucept_application_id`}
              </code>
            </pre>
          </li>
          <li>Replace the values with your actual Intrucept credentials.</li>
          <li>Save the file.</li>
        </ol>
        <p><strong>Note:</strong> Keep this file secure and do not commit it to version control.</p>
      </section>

      <section>
        <h3 className="darkText">Step 3: Open Your Project</h3>
        <ol>
          <li>In IntelliJ IDEA, go to <strong>File</strong> {'>'} <strong>Open</strong>.</li>
          <li>Navigate to your project directory and select it.</li>
          <li>Click "OK" to open the project in IntelliJ IDEA.</li>
          <li>Ensure that the <code>intrucept-config.txt</code> file is present in the root of your project directory.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 4: Verify Plugin Installation</h3>
        <ol>
          <li>Look for the "Intrucept Scans" tool window in the bottom-right corner of the IDE.</li>
          <li>If you don't see it, go to <strong>View</strong> {'>'} <strong>Tool Windows</strong> {'>'} <strong>Intrucept Scans</strong> to open it.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 5: Performing Scans</h3>
        <h4>To perform a SAST Scan:</h4>
        <ol>
          <li>Take IDE Menu and Tools options</li>
          <li>In the dropdown, select <strong>Perform SAST Scan</strong>.</li>
          <li>The scan will initiate, and you'll see the progress in the "Intrucept Scans" tool window.</li>
        </ol>

        <h4>To perform an SCA Scan:</h4>
        <ol>
          <li>Take IDE Menu and Tools options</li>
          <li>In the dropdown, select <strong>Perform SCA Scan</strong>.</li>
          <li>The scan will initiate, and you'll see the progress in the "Intrucept Scans" tool window.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 6: View Scan Results</h3>
        <ol>
          <li>Once the scan is complete, the results will be displayed in the "Intrucept Scans" tool window.</li>
          <li>You can expand each item in the results to see more details about the vulnerabilities or issues found.</li>
          <li>Double-click on a specific issue to navigate to the relevant code in your project.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Important Notes</h3>
        <ul>
          <li>Ensure that the <code>intrucept-config.txt</code> file is always present in your project's root directory when running scans.</li>
          <li>Keep your credentials in the <code>intrucept-config.txt</code> file up to date.</li>
          <li>Do not share or commit the <code>intrucept-config.txt</code> file to version control systems to protect your credentials.</li>
          <li>If you encounter any issues with the scans, verify that your <code>intrucept-config.txt</code> file contains the correct and current credentials.</li>
        </ul>
      </section>
    </div>
  );
};

export default IntelliJIntegration;