import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import '../style.css'; 
import noData from '../../../assets/nodata.png'


const Top10VulnerableApplications = () => {


  const [items, setItems] = useState([])
  const [onLoading, setOnLoading] = useState(false)


  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {
    setOnLoading(true);
    
    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get('/api/v1/users/organization-level-top-10-vulnerable-applications', {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching top 10 vulnerable applications:", error);
    } finally {
      setOnLoading(false);
    }
  };


  return (
    <>


      <div className="dashboardCard dashboardCardFull" style={{
        display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
        borderRadius: 10, padding: 20,
      }}>


        <span style={{ fontSize: 15 }}>TOP 10 VULNERABLE APPLICATIONS</span>
        <hr style={{ borderColor: '#fff' }} />


{items && items.length>0 ?

        <table style={{ width: '100%' }}>

          <thead>

            <th style={{ padding: 5 }}></th>
            <th style={{ padding: 5 }}>
              <div style={{ backgroundColor: '#FF0000', padding: 5, fontSize: 11, borderRadius: 5, textAlign: 'center' }}>CRITICAL</div>
            </th>
            <th style={{ padding: 5 }}>
              <div style={{ backgroundColor: '#A6001B', padding: 5, fontSize: 11, borderRadius: 5, textAlign: 'center' }}>HIGH</div>
            </th>
            <th style={{ padding: 5 }}>
              <div style={{ backgroundColor: '#FFC300', padding: 5, fontSize: 11, borderRadius: 5, textAlign: 'center', color: '#000', }}>MEDIUM</div>
            </th>
            <th style={{ padding: 5 }}>
              <div style={{ backgroundColor: '#B3FFB3', padding: 5, fontSize: 11, borderRadius: 5, color: '#000', textAlign: 'center' }}>LOW</div>
            </th>
            <th style={{ padding: 5 }}>
              <div style={{ backgroundColor: '#555', padding: 5, fontSize: 13, borderRadius: 5, color: '#fff', textAlign: 'center' }}>INFO</div>
            </th>


          </thead>

          <tbody>


            {items.map((item, index) => (
              <tr>
                <td style={{ textAlign: 'left', fontSize: 13, fontWeight: 'bold' }}>
                  {item.application.name}
                </td>
                <td style={{ textAlign: 'center', fontSize: 13 }}>
                {item.criticalCount}
                </td>
                <td style={{ textAlign: 'center', fontSize: 13 }}>
                {item.highCount}
                </td>
                <td style={{ textAlign: 'center', fontSize: 13 }}>
                {item.mediumCount}
                </td>
                <td style={{ textAlign: 'center', fontSize: 13 }}>
                {item.lowCount}
                </td>
                <td style={{ textAlign: 'center', fontSize: 13 }}>
                {item.informationalCount}
                </td>
              </tr>

            ))}




          </tbody>


        </table>

        :

        <div style={{display:'flex', flexDirection:'column', 
          justifyContent:'center', alignItems:'center'}}>
      <hr />
      <img src={noData} width={30} style={{marginTop: '10%'}}/>
      <span style={{ textAlign: 'center', fontSize:11 }}>No data available</span>
      
</div>


        }



      </div>
    </>
  )
}

export default Top10VulnerableApplications