import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
  Typography,
  Paper,
  CircularProgress
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { validatePersonName, validateEmail, validatePhoneNumber, validatePassword } from '../../../helpers/validation';

const EditOrganization = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    orgName: '',
    firstName: '',
    lastName: '',
    primaryContactEmail:'',
    primaryContactPhone:'',
    email: '',
    phone: '',
    country: '',
    taxId: '',
    address: '',
    modules: []
  });


  console.log('formData:',formData)

  const [errors, setErrors] = useState({});

  const modulesList = [
    { id: 'sast', label: 'SAST' },
    { id: 'sca', label: 'SCA' },
    { id: 'dast', label: 'DAST' },
  ];


  const countries = [
    { code: 'AF', name: 'Afghanistan' }, { code: 'AL', name: 'Albania' }, { code: 'DZ', name: 'Algeria' }, { code: 'AS', name: 'American Samoa' },
    { code: 'AD', name: 'Andorra' }, { code: 'AO', name: 'Angola' }, { code: 'AI', name: 'Anguilla' }, { code: 'AQ', name: 'Antarctica' },
    { code: 'AG', name: 'Antigua and Barbuda' }, { code: 'AR', name: 'Argentina' }, { code: 'AM', name: 'Armenia' }, { code: 'AW', name: 'Aruba' },
    { code: 'AU', name: 'Australia' }, { code: 'AT', name: 'Austria' }, { code: 'AZ', name: 'Azerbaijan' }, { code: 'BS', name: 'Bahamas' },
    { code: 'BH', name: 'Bahrain' }, { code: 'BD', name: 'Bangladesh' }, { code: 'BB', name: 'Barbados' }, { code: 'BY', name: 'Belarus' },
    { code: 'BE', name: 'Belgium' }, { code: 'BZ', name: 'Belize' }, { code: 'BJ', name: 'Benin' }, { code: 'BM', name: 'Bermuda' },
    { code: 'BT', name: 'Bhutan' }, { code: 'BO', name: 'Bolivia' }, { code: 'BA', name: 'Bosnia and Herzegovina' }, { code: 'BW', name: 'Botswana' },
    { code: 'BV', name: 'Bouvet Island' }, { code: 'BR', name: 'Brazil' }, { code: 'IO', name: 'British Indian Ocean Territory' },
    { code: 'BN', name: 'Brunei Darussalam' }, { code: 'BG', name: 'Bulgaria' }, { code: 'BF', name: 'Burkina Faso' }, { code: 'BI', name: 'Burundi' },
    { code: 'KH', name: 'Cambodia' }, { code: 'CM', name: 'Cameroon' }, { code: 'CA', name: 'Canada' }, { code: 'CV', name: 'Cape Verde' },
    { code: 'KY', name: 'Cayman Islands' }, { code: 'CF', name: 'Central African Republic' }, { code: 'TD', name: 'Chad' }, { code: 'CL', name: 'Chile' },
    { code: 'CN', name: 'China' }, { code: 'CX', name: 'Christmas Island' }, { code: 'CC', name: 'Cocos (Keeling) Islands' }, { code: 'CO', name: 'Colombia' },
    { code: 'KM', name: 'Comoros' }, { code: 'CG', name: 'Congo' }, { code: 'CD', name: 'Congo, Democratic Republic of the' },
    { code: 'CK', name: 'Cook Islands' }, { code: 'CR', name: 'Costa Rica' }, { code: 'CI', name: 'Cote D\'Ivoire' }, { code: 'HR', name: 'Croatia' },
    { code: 'CU', name: 'Cuba' }, { code: 'CY', name: 'Cyprus' }, { code: 'CZ', name: 'Czech Republic' }, { code: 'DK', name: 'Denmark' },
    { code: 'DJ', name: 'Djibouti' }, { code: 'DM', name: 'Dominica' }, { code: 'DO', name: 'Dominican Republic' }, { code: 'EC', name: 'Ecuador' },
    { code: 'EG', name: 'Egypt' }, { code: 'SV', name: 'El Salvador' }, { code: 'GQ', name: 'Equatorial Guinea' }, { code: 'ER', name: 'Eritrea' },
    { code: 'EE', name: 'Estonia' }, { code: 'ET', name: 'Ethiopia' }, { code: 'FK', name: 'Falkland Islands (Malvinas)' },
    { code: 'FO', name: 'Faroe Islands' }, { code: 'FJ', name: 'Fiji' }, { code: 'FI', name: 'Finland' }, { code: 'FR', name: 'France' },
    { code: 'GF', name: 'French Guiana' }, { code: 'PF', name: 'French Polynesia' }, { code: 'TF', name: 'French Southern Territories' },
    { code: 'GA', name: 'Gabon' }, { code: 'GM', name: 'Gambia' }, { code: 'GE', name: 'Georgia' }, { code: 'DE', name: 'Germany' },
    { code: 'GH', name: 'Ghana' }, { code: 'GI', name: 'Gibraltar' }, { code: 'GR', name: 'Greece' }, { code: 'GL', name: 'Greenland' },
    { code: 'GD', name: 'Grenada' }, { code: 'GP', name: 'Guadeloupe' }, { code: 'GU', name: 'Guam' }, { code: 'GT', name: 'Guatemala' },
    { code: 'GG', name: 'Guernsey' }, { code: 'GN', name: 'Guinea' }, { code: 'GW', name: 'Guinea-Bissau' }, { code: 'GY', name: 'Guyana' },
    { code: 'HT', name: 'Haiti' }, { code: 'HM', name: 'Heard Island and Mcdonald Islands' }, { code: 'VA', name: 'Holy See (Vatican City State)' },
    { code: 'HN', name: 'Honduras' }, { code: 'HK', name: 'Hong Kong' }, { code: 'HU', name: 'Hungary' }, { code: 'IS', name: 'Iceland' },
    { code: 'IN', name: 'India' }, { code: 'ID', name: 'Indonesia' }, { code: 'IR', name: 'Iran' }, { code: 'IQ', name: 'Iraq' },
    { code: 'IE', name: 'Ireland' }, { code: 'IM', name: 'Isle of Man' }, { code: 'IL', name: 'Israel' }, { code: 'IT', name: 'Italy' },
    { code: 'JM', name: 'Jamaica' }, { code: 'JP', name: 'Japan' }, { code: 'JE', name: 'Jersey' }, { code: 'JO', name: 'Jordan' },
    { code: 'KZ', name: 'Kazakhstan' }, { code: 'KE', name: 'Kenya' }, { code: 'KI', name: 'Kiribati' },
    { code: 'KP', name: 'Korea, Democratic People\'s Republic of' }, { code: 'KR', name: 'Korea, Republic of' }, { code: 'KW', name: 'Kuwait' },
    { code: 'KG', name: 'Kyrgyzstan' }, { code: 'LA', name: 'Lao People\'s Democratic Republic' }, { code: 'LV', name: 'Latvia' },
    { code: 'LB', name: 'Lebanon' }, { code: 'LS', name: 'Lesotho' }, { code: 'LR', name: 'Liberia' }, { code: 'LY', name: 'Libyan Arab Jamahiriya' },
    { code: 'LI', name: 'Liechtenstein' }, { code: 'LT', name: 'Lithuania' }, { code: 'LU', name: 'Luxembourg' }, { code: 'MO', name: 'Macao' },
    { code: 'MK', name: 'Macedonia' }, { code: 'MG', name: 'Madagascar' }, { code: 'MW', name: 'Malawi' }, { code: 'MY', name: 'Malaysia' },
    { code: 'MV', name: 'Maldives' }, { code: 'ML', name: 'Mali' }, { code: 'MT', name: 'Malta' }, { code: 'MH', name: 'Marshall Islands' },
    { code: 'MQ', name: 'Martinique' }, { code: 'MR', name: 'Mauritania' }, { code: 'MU', name: 'Mauritius' }, { code: 'YT', name: 'Mayotte' },
    { code: 'MX', name: 'Mexico' }, { code: 'FM', name: 'Micronesia, Federated States of' }, { code: 'MD', name: 'Moldova, Republic of' },
    { code: 'MC', name: 'Monaco' }, { code: 'MN', name: 'Mongolia' }, { code: 'ME', name: 'Montenegro' }, { code: 'MS', name: 'Montserrat' },
    { code: 'MA', name: 'Morocco' }, { code: 'MZ', name: 'Mozambique' }, { code: 'MM', name: 'Myanmar' }, { code: 'NA', name: 'Namibia' },
    { code: 'NR', name: 'Nauru' }, { code: 'NP', name: 'Nepal' }, { code: 'NL', name: 'Netherlands' }, { code: 'AN', name: 'Netherlands Antilles' },
    { code: 'NC', name: 'New Caledonia' }, { code: 'NZ', name: 'New Zealand' }, { code: 'NI', name: 'Nicaragua' }, { code: 'NE', name: 'Niger' },
    { code: 'NG', name: 'Nigeria' }, { code: 'NU', name: 'Niue' }, { code: 'NF', name: 'Norfolk Island' },
    { code: 'MP', name: 'Northern Mariana Islands' }, { code: 'NO', name: 'Norway' }, { code: 'OM', name: 'Oman' }, { code: 'PK', name: 'Pakistan' },
    { code: 'PW', name: 'Palau' }, { code: 'PS', name: 'Palestinian Territory, Occupied' }, { code: 'PA', name: 'Panama' },
    { code: 'PG', name: 'Papua New Guinea' }, { code: 'PY', name: 'Paraguay' }, { code: 'PE', name: 'Peru' }, { code: 'PH', name: 'Philippines' },
    { code: 'PN', name: 'Pitcairn' }, { code: 'PL', name: 'Poland' }, { code: 'PT', name: 'Portugal' }, { code: 'PR', name: 'Puerto Rico' },
    { code: 'QA', name: 'Qatar' }, { code: 'RE', name: 'Reunion' }, { code: 'RO', name: 'Romania' }, { code: 'RU', name: 'Russian Federation' },
    { code: 'RW', name: 'Rwanda' }, { code: 'BL', name: 'Saint Barthelemy' }, { code: 'SH', name: 'Saint Helena' },
    { code: 'KN', name: 'Saint Kitts and Nevis' }, { code: 'LC', name: 'Saint Lucia' }, { code: 'MF', name: 'Saint Martin' },
    { code: 'PM', name: 'Saint Pierre and Miquelon' }, { code: 'VC', name: 'Saint Vincent and the Grenadines' }, { code: 'WS', name: 'Samoa' },
    { code: 'SM', name: 'San Marino' }, { code: 'ST', name: 'Sao Tome and Principe' }, { code: 'SA', name: 'Saudi Arabia' },
    { code: 'SN', name: 'Senegal' }, { code: 'RS', name: 'Serbia' }, { code: 'SC', name: 'Seychelles' }, { code: 'SL', name: 'Sierra Leone' },
    { code: 'SG', name: 'Singapore' }, { code: 'SK', name: 'Slovakia' }, { code: 'SI', name: 'Slovenia' }, { code: 'SB', name: 'Solomon Islands' },
    { code: 'SO', name: 'Somalia' }, { code: 'ZA', name: 'South Africa' },
    { code: 'GS', name: 'South Georgia and the South Sandwich Islands' }, { code: 'ES', name: 'Spain' }, { code: 'LK', name: 'Sri Lanka' },
    { code: 'SD', name: 'Sudan' }, { code: 'SR', name: 'Suriname' }, { code: 'SJ', name: 'Svalbard and Jan Mayen' },
    { code: 'SZ', name: 'Swaziland' }, { code: 'SE', name: 'Sweden' }, { code: 'CH', name: 'Switzerland' },
    { code: 'SY', name: 'Syrian Arab Republic' }, { code: 'TW', name: 'Taiwan' }, { code: 'TJ', name: 'Tajikistan' },
    { code: 'TZ', name: 'Tanzania, United Republic of' }, { code: 'TH', name: 'Thailand' }, { code: 'TL', name: 'Timor-Leste' },
    { code: 'TG', name: 'Togo' }, { code: 'TK', name: 'Tokelau' }, { code: 'TO', name: 'Tonga' }, { code: 'TT', name: 'Trinidad and Tobago' },
    { code: 'TN', name: 'Tunisia' }, { code: 'TR', name: 'Turkey' }, { code: 'TM', name: 'Turkmenistan' }, { code: 'TC', name: 'Turks and Caicos Islands' }, { code: 'TV', name: 'Tuvalu' }, { code: 'UG', name: 'Uganda' },
    { code: 'UA', name: 'Ukraine' }, { code: 'AE', name: 'United Arab Emirates' }, { code: 'GB', name: 'United Kingdom' },
    { code: 'US', name: 'United States' }, { code: 'UM', name: 'United States Minor Outlying Islands' }, { code: 'UY', name: 'Uruguay' },
    { code: 'UZ', name: 'Uzbekistan' }, { code: 'VU', name: 'Vanuatu' }, { code: 'VE', name: 'Venezuela' }, { code: 'VN', name: 'Vietnam' },
    { code: 'VG', name: 'Virgin Islands, British' }, { code: 'VI', name: 'Virgin Islands, U.S.' }, { code: 'WF', name: 'Wallis and Futuna' },
    { code: 'EH', name: 'Western Sahara' }, { code: 'YE', name: 'Yemen' }, { code: 'ZM', name: 'Zambia' }, { code: 'ZW', name: 'Zimbabwe' }
  ];


  useEffect(() => {
    if (!id) {
      navigate('/organizations');
      return;
    }
    fetchOrganization();
  }, [id]);

  const fetchOrganization = async () => {

    const bearerToken = localStorage.getItem('ASIToken');
    try {
      const response = await axios.get(`/api/v1/superadmin/organizations/${id}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      
      // Map the response data to form fields
      setFormData({
        orgName: response.data.name || '',
        firstName: response.data.primaryUser?.firstName || '',
        lastName: response.data.primaryUser?.lastName || '',
        primaryContactEmail:response.data.primaryUser?.email || '',
        primaryContactPhone:response.data.primaryUser?.phoneNumber || '',
        email: response.data.email || '',
        phone: response.data.phone || '',
        country: response.data.country || '',
        taxId: response.data.taxId || '',
        address: response.data.address || '',
        modules: response.data.modules || []
      });
      
      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to fetch organization details');
      navigate('/organizations');
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.orgName.trim()) {
      newErrors.orgName = 'Please enter organization name';
    } else if (/[!@#$%^&*()]/.test(formData.orgName)) {
      newErrors.orgName = 'Invalid organization name';
    }

    if (!formData.firstName.trim()) {
      newErrors.firstName = 'Please enter primary contact first name';
    } else if (/[\d!@#$%^&*()]/.test(formData.firstName)) {
      newErrors.firstName = 'Invalid first name';
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Please enter primary contact last name';
    } else if (/[\d!@#$%^&*()]/.test(formData.lastName)) {
      newErrors.lastName = 'Invalid last name';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Please enter organization email';
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email';
    }

    if (!formData.primaryContactEmail.trim()) {
      newErrors.primaryContactEmail = 'Please enter primary contact\'s email';
    } else if (!validateEmail(formData.primaryContactEmail)) {
      newErrors.primaryContactEmail = 'Invalid email';
    }

    if (!formData.primaryContactPhone.trim()) {
      newErrors.primaryContactPhone = 'Please enter primary contact\'s phone number';
    } else if (!validatePhoneNumber(formData.primaryContactPhone)) {
      newErrors.primaryContactPhone = 'Invalid phone number';
    }

    if (!formData.country) {
      newErrors.country = 'Please select a country';
    }

    if (formData.taxId && !/^[A-Z0-9-]+$/i.test(formData.taxId)) {
      newErrors.taxId = 'Invalid tax ID';
    }

    if (formData.modules.length === 0) {
      newErrors.modules = 'Please select at least one module';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      const bearerToken = localStorage.getItem('ASIToken');
      
      // Map form data back to API format
      const apiData = {
        name: formData.orgName,
        primaryUser: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email:formData.primaryContactEmail,
          phone: formData.primaryContactPhone,
        },
        email: formData.email,
        country: formData.country,
        taxId: formData.taxId,
        address: formData.address,
        modules: formData.modules
      };

      try {
        await axios.put(`/api/v1/superadmin/organizations/${id}`, apiData, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        toast.success('Organization updated successfully');
        navigate('/organizations');
      } catch (error) {
        toast.error(error.response?.data?.message || 'Failed to update organization');
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
console.log('comes to handlchange')
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleModuleChange = (moduleId) => {
    setFormData(prev => ({
      ...prev,
      modules: prev.modules.includes(moduleId)
        ? prev.modules.filter(id => id !== moduleId)
        : [...prev.modules, moduleId]
    }));
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 3, maxWidth: 800, mx: 'auto', mt: 4 }}>
      <Box sx={{ p: 3 }}>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2, 
            mb: 3,
            p: 2,
            borderRadius: 1,
            boxShadow: 1
          }}
        >
          <Button
            variant="outlined"
            onClick={() => navigate('/organizations')}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
          <h3 style={{color:'#fff'}}>Edit Organization</h3>
        </Box>
      </Box>

      <Box component="form" onSubmit={handleSubmit} noValidate>
        <TextField
          fullWidth
          margin="normal"
          label="Organization Name"
          name="orgName"
          value={formData.orgName}
          onChange={handleChange}
          error={!!errors.orgName}
          helperText={errors.orgName}
          required
          sx={{
            '& label': { color: 'white' },
            '& label.Mui-focused': { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'white' },
              '&:hover fieldset': { borderColor: 'white' },
              '&.Mui-focused fieldset': { borderColor: 'white' },
            },
            '& .MuiFormHelperText-root': { color: 'white' },
            '& input': { color: 'white' },
          }}
        />



        <TextField
          fullWidth
          margin="normal"
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          required
          sx={{
            '& label': { color: 'white' },
            '& label.Mui-focused': { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'white' },
              '&:hover fieldset': { borderColor: 'white' },
              '&.Mui-focused fieldset': { borderColor: 'white' },
            },
            '& .MuiFormHelperText-root': { color: 'white' },
            '& input': { color: 'white' },
          }}
        />

      

<FormControl fullWidth margin="normal" error={!!errors.country} required>
          <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Country</InputLabel>
          <Select
            name="country"
            value={formData.country}
            onChange={handleChange}
            label="Country"
            sx={{
              '& .MuiSelect-select': {
                color: 'white',
              },
              '& .MuiInputBase-input': {
                color: 'white !important',  
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white',
              },
              '.MuiSvgIcon-root': {
                color: 'white',
              }
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: '#333',
                  '& .MuiMenuItem-root': {
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.08)',
                    },
                    '&.Mui-selected': {
                      bgcolor: 'rgba(255, 255, 255, 0.16)',
                      color: 'white',  
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.24)',
                      }
                    }
                  },
                },
              },
            }}
          >
            {countries.map(country => (
              <MenuItem key={country.code} value={country.code}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
          {errors.country && (
            <Typography color="error" variant="caption">
              {errors.country}
            </Typography>
          )}
        </FormControl>

        <TextField
          fullWidth
          margin="normal"
          label="Tax ID"
          name="taxId"
          value={formData.taxId}
          onChange={handleChange}
          error={!!errors.taxId}
          helperText={errors.taxId}
          sx={{
            '& label': { color: 'white' },
            '& label.Mui-focused': { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'white' },
              '&:hover fieldset': { borderColor: 'white' },
              '&.Mui-focused fieldset': { borderColor: 'white' },
            },
            '& .MuiFormHelperText-root': { color: 'white' },
            '& input': { color: 'white' },
          }}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Address"
          name="address"
          multiline
          rows={4}
          value={formData.address}
          onChange={handleChange}
          inputProps={{ maxLength: 1000 }}
          sx={{
            '& label': { color: 'white' }, 
            '& label.Mui-focused': { color: 'white' }, 
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'white' }, 
              '&:hover fieldset': { borderColor: 'white' },
              '&.Mui-focused fieldset': { borderColor: 'white' }, 
              '& input': {
                color: 'white',
              },
            },
            '& .MuiFormHelperText-root': { 
              color: 'white', 
            },
            '& .MuiInputBase-input': {
              color: 'white', 
            }
          }}
        />

        <FormGroup sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Modules
          </Typography>
          {modulesList.map(module => (
            <FormControlLabel
              key={module.id}
              control={
                <Checkbox
                  checked={formData.modules.includes(module.id)}
                  onChange={() => handleModuleChange(module.id)}
                />
              }
              label={module.label}
            />
          ))}
          {errors.modules && (
            <span style={{color:'red'}}>
              {errors.modules}
            </span>
          )}
        </FormGroup>

        <hr style={{borderColor:'#fff'}}/>

        <span style={{fontWeight:'bold', fontSize:20}}>Primary User (Admin) Details</span>
        <hr style={{borderColor:'#fff'}}/>

        <Box sx={{ display: 'flex', gap: 2 }}>

         
          <TextField
            fullWidth
            margin="normal"
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
            required
            sx={{
              '& label': { color: 'white' },
              '& label.Mui-focused': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiFormHelperText-root': { color: 'white' },
              '& input': { color: 'white' },
            }}
          />

          <TextField
            fullWidth
            margin="normal"
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
            required
            sx={{
              '& label': { color: 'white' },
              '& label.Mui-focused': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiFormHelperText-root': { color: 'white' },
              '& input': { color: 'white' },
            }}
          />

        <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="primaryContactEmail"
            value={formData.primaryContactEmail}
            onChange={handleChange}
            error={!!errors.primaryContactEmail}
            helperText={errors.primaryContactEmail}
            required
            sx={{
              '& label': { color: 'white' },
              '& label.Mui-focused': { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
                '&:hover fieldset': { borderColor: 'white' },
                '&.Mui-focused fieldset': { borderColor: 'white' },
              },
              '& .MuiFormHelperText-root': { color: 'white' },
              '& input': { color: 'white' },
            }}
          />

    <TextField
          fullWidth
          margin="normal"
          label="Phone"
          name="primaryContactPhone"
          value={formData.primaryContactPhone}
          onChange={handleChange}
          error={!!errors.primaryContactPhone}
          helperText={errors.primaryContactPhone}
          required
          sx={{
            '& label': { color: 'white' },
            '& label.Mui-focused': { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'white' },
              '&:hover fieldset': { borderColor: 'white' },
              '&.Mui-focused fieldset': { borderColor: 'white' },
            },
            '& .MuiFormHelperText-root': { color: 'white' },
            '& input': { color: 'white' },
          }}
        />

        </Box>

        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{background:'#e30203'}}
            disabled={loading}
          >
            Save Changes to Organization
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default EditOrganization;