import React from 'react';

const GitHubActionsIntegration = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="darkText">GitHub Actions Integration Instructions for SAST and SCA Scans</h2>
      <hr className="mb-6"/>

      <section className="mb-8">
        <h3 className="darkText">Step 1: Configure GitHub Secrets</h3>
        <ol className="list-decimal pl-6">
          <li>Go to your GitHub repository</li>
          <li>Click on <strong>Settings</strong></li>
          <li>Click on <strong>Secrets and variables</strong> {'->'} <strong>Actions</strong></li>
          <li>Click on <strong>New repository secret</strong></li>
          <li>Add the following secrets:
            <ul className="list-disc pl-6">
              <li><strong>INTRUCEPT_CLIENT_ID</strong></li>
              <li><strong>INTRUCEPT_CLIENT_SECRET</strong></li>
              <li><strong>INTRUCEPT_APPLICATION_ID</strong></li>
            </ul>
          </li>
        </ol>
      </section>

      <section className="mb-8">
        <h3 className="darkText">Step 2: Create GitHub Actions Workflow for SAST Check</h3>
        <p className="mb-4">Create a file named <code className="bg-gray-100 px-2 py-1 rounded">sast.yml</code> in the <code className="bg-gray-100 px-2 py-1 rounded">.github/workflows</code> directory of your project repository with the following content:</p>
        <pre className="bg-gray-100 p-4 rounded overflow-x-auto">
          <code>
{`name: SAST Check
on:
  push:
    branches: [ master ]  # Adjust this to your master branch name
  pull_request:
    branches: [ master ]  # Optionally run on PRs
jobs:
  sast-check:
    runs-on: ubuntu-latest
    steps:
      - uses: actions/checkout@v3
      
      - name: Create project zip
        run: |
          zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"
      
      - name: Perform SAST Scan
        id: sast
        run: |
          RESPONSE=$(curl -X POST \
            -H "Client-ID: $\{{ secrets.INTRUCEPT_CLIENT_ID }}" \
            -H "Client-Secret: $\{{ secrets.INTRUCEPT_CLIENT_SECRET }}" \
            -F "projectZipFile=@project.zip" \
            -F "applicationId=$\{{ secrets.INTRUCEPT_APPLICATION_ID }}" \
            -F "scanName=SAST Scan - $\{{ github.sha }}" \
            -F "language=javascript" \
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans)          
          
          
          # Check for error in response
          ERROR=$(echo "$RESPONSE" | jq -r '.error // empty')
          if [ ! -z "$ERROR" ]; then
            echo "Error: $ERROR"
            exit 1
          else
            VULNS_TABLE=$(echo "$RESPONSE" | jq -r '.vulnsTable')
            echo "Vulnerabilities Table:"
            echo "$VULNS_TABLE"
          fi`}
          </code>
        </pre>
      </section>

      <section className="mb-8">
        <h3 className="darkText">Step 3: Create GitHub Actions Workflow for SCA Check</h3>
        <p className="mb-4">Create a file named <code className="bg-gray-100 px-2 py-1 rounded">sca.yml</code> in the <code className="bg-gray-100 px-2 py-1 rounded">.github/workflows</code> directory of your project repository with the following content:</p>
        <pre className="bg-gray-100 p-4 rounded overflow-x-auto">
          <code>
{`name: SCA Check
on:
  push:
    branches: [ master ]  # Adjust this to your master branch name
  pull_request:
    branches: [ master ]  # Optionally run on PRs
jobs:
  sca-check:
    runs-on: ubuntu-latest
    steps:
      - uses: actions/checkout@v3
      
      - name: Create project zip
        run: |
          zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"
      
      - name: Perform SCA Scan
        id: sca
        run: |
          RESPONSE=$(curl -X POST \
            -H "Client-ID: $\{{ secrets.INTRUCEPT_CLIENT_ID }}" \
            -H "Client-Secret: $\{{ secrets.INTRUCEPT_CLIENT_SECRET }}" \
            -F "projectZipFile=@project.zip" \
            -F "applicationId=$\{{ secrets.INTRUCEPT_APPLICATION_ID }}" \
            -F "scanName=SCA Scan - $\{{ github.sha }}" \
            -F "language=javascript" \
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans)          
          
          
          # Check for error in response
          ERROR=$(echo "$RESPONSE" | jq -r '.error // empty')
          if [ ! -z "$ERROR" ]; then
            echo "Error: $ERROR"
            exit 1
          else
            VULNS_TABLE=$(echo "$RESPONSE" | jq -r '.vulnsTable')
            echo "Vulnerabilities Table:"
            echo "$VULNS_TABLE"
          fi`}
          </code>
        </pre>
      </section>

      <section>
        <h3 className="darkText">Explanation of the Workflows</h3>
        <p>These GitHub Actions workflows for SAST and SCA scanning do the following:</p>
        <ol className="list-decimal pl-6">
          <li>Trigger on push or pull request to the main branch (adjust as needed)</li>
          <li>Create a zip file of the project for scanning, excluding unnecessary files and directories</li>
          <li>Perform the respective scan (SAST or SCA) using the Intrucept API</li>
          <li>Display the vulnerabilities table from the scan results</li>
          <li>Check the scan result and fail the workflow if issues are found</li>
        </ol>
      </section>

      <p className="mt-8 text-gray-600"><strong>Note:</strong> Remember to adjust the branch names to match your project's specific requirements. Ensure that you have added the required secrets (INTRUCEPT_CLIENT_ID, INTRUCEPT_CLIENT_SECRET, INTRUCEPT_APPLICATION_ID) to your GitHub repository before running these workflows.</p>
    </div>
  );
};

export default GitHubActionsIntegration;