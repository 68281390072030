import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { FaDownload, FaFile } from 'react-icons/fa';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect,
  CTextarea
} from '@coreui/react';

import { useParams, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ShimmerTable } from "react-shimmer-effects";
import { validateEntityName } from '../../../../helpers/validation';

const Ticket = (props) => {

  const navigate = useNavigate();
  const location = useLocation();

  console.log('props.ticketId:', props.ticketId)

  const [updateText, setUpdateText] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [theStatus, setTheStatus] = useState("");
 
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);  

  const [users, setUsers] = useState([]);
  const [assignedTo, setAssignedTo] = useState({});
  const [notes, setNotes] = useState('');
  
  const [ticket, setTicket] = useState({});
  const [ticketId, setTicketId] = useState("");  

  const [errors, setErrors] = useState({
    updateText: '',
    attachments: '',
    assignedTo: '',
    general: ''
  });

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const toaster = useRef();   

  const encodeScriptContent = (content) => {
    return content.replace(/<script|<\/script|javascript:|on\w+=/gi, match => {
      return match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    });
  };

  /*
  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setAttachments(selectedFiles);
  };
*/

  const handleFileChange = (event) => {
    
    const selectedFiles = Array.from(event.target.files);
    const allowedExtensions = ['.png', '.jpeg', '.jpg', '.doc', '.docx', '.gif', '.pdf', '.txt', '.ppt', '.xls', '.xlsx', '.zip', '.csv'];
    
    const invalidFiles = selectedFiles.filter(file => {
      const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
      return !allowedExtensions.includes(fileExtension);
    });
  
    if (invalidFiles.length > 0) {
      event.target.value = ''; // Clear the file input
      setErrors(prevErrors => ({ 
        ...prevErrors, 
        attachments: 'Invalid file type. Please upload only allowed file types.' 
      }));
      return;
    }
  
    setAttachments(selectedFiles);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    var arr = location.search.split('=');
    var theTicketId = props.ticketId;//arr[1];
    setTicketId(theTicketId);
    fetchUsers();
    loadTicketDetails(theTicketId);
  }, []); 

  const [user, setUser] = useState({});
  
  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    setUser(JSON.parse(localStorage.getItem('ASIUser')));
  };

  const loadTicketDetails = async (theTicketId) => {
    setLoading(true);
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/organizations/tickets/${theTicketId}`, {
    headers: { Authorization: `Bearer ${token}` },
});
    setTicket(response.data.ticket); 
    setTheStatus(response.data.ticket.status);   
    setAssignedTo(response.data.ticket.assignedTo._id)
    setNotes(response.data.ticket.note);
    setLoading(false);
  };

  const fetchUsers = async () => {

    setLoading(true);
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('/api/v1/organizations/users/all/active', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setUsers(response.data.users);
    
    setLoading(false);
  };  


  const validateForm = () => {
    let isValid = true;
    let newErrors = {
      updateText: '',
      attachments: '',
      assignedTo: '',
      general: ''
    };

    if (updateText.trim() === '') {
      newErrors.updateText = 'Please add an update.';
      isValid = false;
    } else if (updateText.length > 10000) {
      newErrors.updateText = 'Update text must not exceed 10,000 characters.';
      isValid = false;
    }

    if (!assignedTo) {
      newErrors.assignedTo = 'Please assign the ticket to a user.';
      isValid = false;
    }

    for (let i = 0; i < attachments.length; i++) {
      if (isExceeding100MB(attachments[i])) {
        newErrors.attachments = 'One or more files exceed the 100MB limit.';
        isValid = false;
        break;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const isExceeding100MB = (file) => {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    return fileSizeInMB > 100;
  };

  const addTicketUpdate = () => {

    if (validateForm()) {
      setLoading(true);      
      const bearerToken = localStorage.getItem('ASIToken');
      const formData = new FormData();
      formData.append('ticketId', ticket._id);
      formData.append('updateText', encodeScriptContent(updateText));
      formData.append('assignedTo', assignedTo);
      formData.append('status', theStatus);

      for (let i = 0; i < attachments.length; i++) {
        formData.append('ticketUpdateAttachments', attachments[i]);
      }

      const headers = new Headers();
      headers.append('Authorization', `Bearer ${bearerToken}`);
      
      fetch(`${global.backendUrl}/api/v1/organizations/ticket-updates`, {
        method: 'POST',
        headers: headers,
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        if(data.hasOwnProperty('error')){
          setErrors(prevErrors => ({ ...prevErrors, general: data.error }));
          setLoading(false);
        }
        else if(data.hasOwnProperty('err')){
          setLoading(false);
          setErrors(prevErrors => ({ ...prevErrors, general: "Something went wrong. Please try again" }));
        } else {
          setSubmissionSuccess(true);
          setLoading(false);
          toast('Ticket updated', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTicket(data.ticket);
          setUpdateText('');
          setAttachments([]);
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        setErrors(prevErrors => ({ ...prevErrors, general: "An error occurred. Please try again." }));
      });
    }   
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate('/tickets');
  }; 

  function TicketDetails({ ticket }) {
    let details = '';
  
    if (ticket.tool === 'SAST') {
      details = (
        <table style={{marginLeft:-10}}>
          <tbody>
            <tr>
              <td><strong>Filepath:</strong></td>
              <td>{ticket.filePath}</td>
            </tr>
            <tr>
              <td><strong>Start:</strong></td>
              <td>Col: {ticket.startCol}, Line: {ticket.startLine}, Offset: {ticket.startOffset}</td>
            </tr>
            <tr>
              <td><strong>End:</strong></td>
              <td>Col: {ticket.endCol}, Line: {ticket.endLine}, Offset: {ticket.endOffset}</td>
            </tr>
          </tbody>
        </table>
      );
    } else if (ticket.tool === 'SCA') {
      details = (
        <table style={{marginLeft:-10}}>
          <tbody>
            <tr>
              <td><strong>Package:</strong></td>
              <td>{ticket.packageName}</td>
            </tr>
            <tr>
              <td><strong>Affected Versions:</strong></td>
              <td>{ticket.affectedVersions}</td>
            </tr>
          </tbody>
        </table>
      );
    } else if (ticket.tool === 'DAST') {
      details = (
        <table style={{marginLeft:-10}}>
          <tbody>
            <tr>
              <td><strong>URL:</strong></td>
              <td>{ticket.url}</td>
            </tr>
          </tbody>
        </table>
      );
    }
  
    return (
      <span>
       
          {details}
      </span>
    );
  }

  
const downloadFile = async (filePath, fileName) => {

  if (!filePath) {
    toast.error('Invalid file path');
    return;
  }

  try {
    const bearerToken = localStorage.getItem('ASIToken');
    
    // Use the raw filePath without encoding first, since your route accepts (*) wildcard
    const response = await fetch(global.backendUrl+ `/api/v1/organizations/tickets/attachment/${filePath}`, {
      method: 'GET',
      headers: { 
        'Authorization': `Bearer ${bearerToken}`,
      }
    });

    if (!response.ok) {
      throw new Error(`Download failed: ${response.statusText}`);
    }

    // Get the blob from the response
    const blob = await response.blob();
    
    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.download = fileName; // Use the provided filename
    
    // Trigger download
    document.body.appendChild(link);
    link.click();
    
    // Cleanup
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 100);

  } catch (error) {
    console.error('Error downloading file:', error);
    toast.error('Failed to download file. Please try again.');
  }
};


  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '80%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: "center", }}>

            <h4 style={{color:'#000'}}>Ticket #{ticket.ticketId}</h4>

{ticket && ticket.status && 
            <div style={{ display: "flex", alignItems: "center", flexDirection:'row',  }}>
        <span style={{
          fontSize: '12px',
          fontWeight: 'normal',
          padding: '5px 10px',
          borderRadius: '5px',
          display: 'inline-block',
          textTransform: 'uppercase',
          backgroundColor: (() => {
            switch(ticket.status.toLowerCase()) {
              case 'open':
                return '#e74c3c';
              case 'in progress':
                return '#3498db';
              case 'resolved':
                return '#2ecc71';
              case 'on hold':
                return '#f39c12';
              default:
                return '#95a5a6';
            }
          })(),
          color: '#ffffff'
        }}>
          {ticket.status}
        </span>
      </div>
}



            <CButton
              onClick={goBack}
              style={{
                width: 200,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 13,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Tickets
            </CButton>
          </div>

          {!loading ? (
  <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 30, backgroundColor: '#252B3B', padding: 15 }}>
    <h4>{ticket.title}</h4>
    <p>{ticket.description}</p>

<hr style={{borderColor:'#fff'}}/>

    <TicketDetails ticket={ticket} />
    <hr style={{borderColor:'#fff'}}/>


   
    <span style={{color:'#fff',marginTop:15, fontSize:13}}>
    <strong>Assigned To:</strong> {ticket.assignedTo ? ticket.assignedTo.firstName + ' ' + ticket.assignedTo.lastName : ''}
    </span>
    <span style={{color:'#fff',marginTop:15, fontSize:13}}>
    <strong>Opened At:</strong> {(new Date(ticket.createdAt)).toLocaleDateString()} {(new Date(ticket.createdAt)).toLocaleTimeString()}
    </span>

    <span style={{color:'#fff',marginTop:15, fontSize:13}}>
      <strong>Notes:</strong> {notes}
    </span>

    {/* Ticket Attachments Section */}
    {ticket.attachments && ticket.attachments.length > 0 && (
      <div style={{ 
        marginTop: 15,
        padding: 10, 
        backgroundColor: '#1a142b',
        borderRadius: 5 
      }}>
        <div style={{ 
          color: '#888', 
          fontSize: 12, 
          marginBottom: 8 
        }}>
          Ticket Attachments:
        </div>
        <div style={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: 10 
        }}>
          {ticket.attachments.map((filePath, fileIndex) => {
            const fileName = filePath.split('/').pop();
            const fileExt = fileName.split('.').pop().toLowerCase();

            let iconColor = '#fff';
            switch(fileExt) {
              case 'pdf':
                iconColor = '#ff4444';
                break;
              case 'doc':
              case 'docx':
                iconColor = '#4444ff';
                break;
              case 'xls':
              case 'xlsx':
                iconColor = '#44ff44';
                break;
              case 'zip':
              case 'rar':
                iconColor = '#ffaa00';
                break;
              case 'jpg':
              case 'jpeg':
              case 'png':
                iconColor = '#ff44ff';
                break;
            }

            return (
              <div
                key={fileIndex}
                onClick={() => downloadFile(filePath, fileName)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 12px',
                  backgroundColor: '#2c2442',
                  borderRadius: 4,
                  cursor: 'pointer',
                  transition: 'all 0.2s ease',
                  gap: 8,
                  maxWidth: '250px',
                  border: '1px solid #3c3452',
                  userSelect: 'none'
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#342b4f';
                  e.currentTarget.style.transform = 'translateY(-1px)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#2c2442';
                  e.currentTarget.style.transform = 'translateY(0)';
                }}
              >
                <FaFile size={14} color={iconColor} />
                <span style={{ 
                  color: 'white', 
                  fontSize: 12,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  flex: 1,
                  minWidth: 0
                }}>
                  {fileName}
                </span>
                <FaDownload 
                  size={12} 
                  color="#888"
                  style={{ marginLeft: 'auto' }}
                />
              </div>
            );
          })}
        </div>
      </div>
    )}
  </div>
) : (
  <ShimmerTable row={8} col={10} />
)}

{!loading ? (
    <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 30, backgroundColor: '#252B3B', padding: 15 }}>
      {ticket.ticketUpdates && ticket.ticketUpdates.map((update, index) => (
        <div key={index} style={{ backgroundColor: '#251C3B', padding: 15, marginTop: 10 }}>
          <p style={{ color: 'white' }}>{update.updateText}</p>
          
          {/* Attachments Section */}
          {update.attachments && update.attachments.length > 0 && (
            <div style={{ 
              marginTop: 10, 
              padding: 10, 
              backgroundColor: '#1a142b',
              borderRadius: 5 
            }}>
              <div style={{ 
                color: '#888', 
                fontSize: 12, 
                marginBottom: 8 
              }}>
                Attachments:
              </div>
              <div style={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 10 
              }}>
                {update.attachments.map((filePath, fileIndex) => {
                  const fileName = filePath.split('/').pop(); // Get just the filename
                  const fileExt = fileName.split('.').pop().toLowerCase(); // Get file extension

                  // Get appropriate icon color based on file type
                  let iconColor = '#fff';
                  switch(fileExt) {
                    case 'pdf':
                      iconColor = '#ff4444';
                      break;
                    case 'doc':
                    case 'docx':
                      iconColor = '#4444ff';
                      break;
                    case 'xls':
                    case 'xlsx':
                      iconColor = '#44ff44';
                    default:
                      iconColor = '#44ff44';
                      break;
                  }

                  return (
                    <div
                      key={fileIndex}
                      onClick={() => downloadFile(filePath, fileName)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 12px',
                        backgroundColor: '#2c2442',
                        borderRadius: 4,
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                        gap: 8,
                        maxWidth: '250px',
                        border: '1px solid #3c3452',
                        userSelect: 'none'
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = '#342b4f';
                        e.currentTarget.style.transform = 'translateY(-1px)';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = '#2c2442';
                        e.currentTarget.style.transform = 'translateY(0)';
                      }}
                    >
                      <FaFile size={14} color={iconColor} />
                      <span style={{ 
                        color: 'white', 
                        fontSize: 12,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        flex: 1,
                        minWidth: 0
                      }}>
                        {fileName}
                      </span>
                      <FaDownload 
                        size={12} 
                        color="#888"
                        style={{ marginLeft: 'auto' }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div style={{ marginTop: 10 }}>
            <span style={{ color: 'white', fontSize: 13 }}>
              Updated By: {update.updatedBy.firstName} {update.updatedBy.lastName}
            </span>
            <br/>
            <span style={{ color: 'white', fontSize: 13 }}>
              Updated At: {(new Date(update.createdAt)).toLocaleDateString()} {(new Date(update.createdAt)).toLocaleTimeString()}
            </span>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <ShimmerTable row={8} col={10} />
  )}

          {loading ? (
            <ShimmerTable row={8} col={10} />
          ) : (
            <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
              <CFormLabel htmlFor="formTextarea" style={{ marginTop: 30, color: 'white' }}>Add update below <span style={{color:'red'}}>*</span></CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <textarea
                  id="formTextarea"
                  placeholder="Add update here"
                  autoComplete="updateText"
                  className="form-control white-input"
                  value={updateText}
                  onChange={(e) => setUpdateText(e.target.value)}
                  style={{ width: '100%', resize: 'vertical', minHeight: '100px' }}
                  maxLength={10000}
                />
                {errors.updateText && <div style={{ color: 'red', marginTop: '5px' }}>{errors.updateText}</div>}
                <div style={{ color: 'white', marginTop: '5px' }}>{updateText.length}/10000 characters</div>
              </CInputGroup>

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white' }}>Add file(s)</CFormLabel><br/>
              <span>Allowed types: .png, .jpeg, .jpg, .doc, .docx, .gif, .pdf, .txt, .ppt, .xls, .xlsx, .zip, .csv</span><br/>
              <CInputGroup className="" style={{ flexDirection: 'column', }}>
                <CFormInput
                  placeholder="Upload files(s)"
                  autoComplete="username"
                  type="file" 
                  className="white-input"
                  size="sm"
                  id="inputFile"
                  multiple
                  onChange={handleFileChange}
                  accept=".png,.jpeg,.jpg,.doc,.docx,.gif,.pdf,.txt,.ppt,.xls,.xlsx,.zip,.csv"
                  style={{ width: '100%' }}
                />
                {errors.attachments && <div style={{ color: 'red', marginTop: '5px' }}>{errors.attachments}</div>}
              </CInputGroup>    

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Status</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormSelect
                  id="theStatus"
                  className="white-input"
                  value={theStatus}
                  onChange={(e) => setTheStatus(e.target.value)}
                  style={{ width: '100%' }}
                >
                  <option value="Open">OPEN</option>
                <option value="In Progress">IN PROGRESS</option>
                <option value="On Hold">ON HOLD</option>
                <option value="Resolved">RESOLVED</option>
                </CFormSelect>
              </CInputGroup>     

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Assigned To</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>

                <CFormSelect
                  id="assignedTo"
                  className="white-input"
                  onChange={(e) => setAssignedTo(e.target.value)}
                  value={assignedTo}
                  disabled={user.role === 'Tester'}
                  style={{ width: '100%' }}
                >
                  {users.map(user => (
                    <option key={user._id} value={user._id}>
                      {user.firstName} {user.lastName}  ({user.email})
                    </option>
                  ))}
                </CFormSelect>
                {errors.assignedTo && <div style={{ color: 'red', marginTop: '5px' }}>{errors.assignedTo}</div>}
              </CInputGroup>    

              {errors.general && <div style={{ color: 'red', marginTop: '15px' }}>{errors.general}</div>}

              <CButton
                style={{
                  width: '100%',
                  marginTop: '3%',
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 13,
                  background: '#e50202'
                }}
                color="primary"
                className="px-3"
                onClick={addTicketUpdate}
                disabled={submitting}
              >
                {submitting ?
                  <CircularProgress color="primary" size={24} 
                  style={{ marginTop: 10, color: '#fff' }} />
                  :
                  'Submit Update'
                }
              </CButton>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Ticket;