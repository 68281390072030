import React from 'react';

const AntGitHubActionsIntegration = () => {
  return (
    <div>
      <h3 className="darkText">GitHub Actions Integration Instructions for Apache Ant with SAST and SCA Scans</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitHub Secrets</h4>
      <ol>
        <li>Go to your GitHub repository.</li>
        <li>Click on <strong>Settings</strong>.</li>
        <li>Click on <strong>Secrets and variables</strong> {'->'} <strong>Actions</strong>.</li>
        <li>Click on <strong>New repository secret</strong>.</li>
        <li>Add secrets for <strong>INTRUCEPT_CLIENT_ID</strong>, <strong>INTRUCEPT_CLIENT_SECRET</strong>, and <strong>INTRUCEPT_APPLICATION_ID</strong>.</li>
      </ol>

      <h4 className="darkText">Step 2: Create a GitHub Actions Workflow for Apache Ant Build and SAST Scan</h4>
      <p>Create a file named <code>sast.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: SAST Check
on:
  push:
    branches: [ main ]
  pull_request:
    branches: [ main ]
jobs:
  sast-check:
    runs-on: ubuntu-latest
    steps:
      - uses: actions/checkout@v3
      
      - name: Set up Java JDK
        uses: actions/setup-java@v3
        with:
          distribution: 'temurin'
          java-version: '11'
      
      - name: Build with Ant
        run: ant build  # Adjust to your actual build command
      
      - name: Create project zip
        run: zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"
      
      - name: Perform SAST Scan
        id: sast
        run: |
          RESPONSE=$(curl -X POST \
            -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \
            -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \
            -F "projectZipFile=@project.zip" \
            -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \
            -F "scanName=Golang SAST Scan - \${{ github.sha }}" \
            -F "language=kotlin" \
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans)
          
          # Check for error in response.
          ERROR=$(echo "$RESPONSE" | jq -r '.error // empty')
          if [ ! -z "$ERROR" ]; then
             echo "Error: $ERROR"
             exit 1
          else
             VULNS_TABLE=$(echo "$RESPONSE" | jq -r '.vulnsTable')
             echo "Vulnerabilities Table:"
             echo "$VULNS_TABLE"
          fi `}
        </code>
      </pre>

      <h4 className="darkText">Step 3: Create a GitHub Actions Workflow for Apache Ant Build and SCA Scan</h4>
      <p>Create a file named <code>sca.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: SCA Check
on:
  push:
    branches: [ main ]
  pull_request:
    branches: [ main ]
jobs:
  sca-check:
    runs-on: ubuntu-latest
    steps:
      - uses: actions/checkout@v3
      
      - name: Set up Java JDK
        uses: actions/setup-java@v3
        with:
          distribution: 'temurin'
          java-version: '11'
      
      - name: Build with Ant
        run: ant build  # Adjust to your actual build command
      
      - name: Create project zip
        run: zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"
      
      - name: Perform SCA Scan
        id: sca
        run: |
          RESPONSE=$(curl -X POST \
            -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \
            -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \
            -F "projectZipFile=@project.zip" \
            -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \
            -F "scanName=Golang SCA Scan - \${{ github.sha }}" \
            -F "language=Kotlin" \
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans)          
          
            # Check for error in response
            ERROR=$(echo "$RESPONSE" | jq -r '.error // empty')
            if [ ! -z "$ERROR" ]; then
              echo "Error: $ERROR"
              exit 1
            else
              VULNS_TABLE=$(echo "$RESPONSE" | jq -r '.vulnsTable')
              echo "Vulnerabilities Table:"
              echo "$VULNS_TABLE"
            fi`}
        </code>
      </pre>

      <h4 className="darkText">Explanation of the Workflows</h4>
      <p>These GitHub Actions workflows for Apache Ant build with SAST and SCA scanning do the following:</p>
      <ol>
        <li>Trigger on push or pull request to the main branch.</li>
        <li>Set up the Java JDK environment.</li>
        <li>Build the project using Apache Ant.</li>
        <li>Create a zip file of the project for scanning, excluding unnecessary files and directories.</li>
        <li>Perform the respective scan (SAST or SCA) using the Intrucept API.</li>
        <li>Display the vulnerabilities table from the scan results.</li>
        <li>Check the scan result and fail the workflow if issues are found.</li>
      </ol>

      <p><strong>Note:</strong> Remember to adjust the Java version, branch names, and the Ant build command to match your project's specific requirements. Ensure that you have added the required secrets (INTRUCEPT_CLIENT_ID, INTRUCEPT_CLIENT_SECRET, INTRUCEPT_APPLICATION_ID) to your GitHub repository before running these workflows.</p>
    </div>
  );
};

export default AntGitHubActionsIntegration;