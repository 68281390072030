import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,  
  CHeaderNav,
  CNavLink,
  CNavItem,
} from '@coreui/react'


import { AppHeaderDropdown } from './header/index';

import { useLocation } from 'react-router-dom'
import { AiOutlineSearch } from "react-icons/ai";

const AppHeader = () => {

  const [currentRoute, setCurrentRoute] = useState('');
  const [notiPanelVisible, setNotiPanelVisible] = useState(false);

  const [user, setUser] = useState(null);

  const [clickedOutside, setClickedOutside] = useState(false);
  const [last10Alerts, setLast10Alerts] = useState([]);

  const myRef = useRef();

  const location = useLocation();

  useEffect(() => {

    setCurrentRoute('/');
    getUserDetails();

  }, [])  


  const getUserDetails = () => {

    setUser(JSON.parse(localStorage.getItem('ASIUser')));
  }    

  const handleClickOutside = e => {

    if (myRef.current && !myRef.current.contains(e.target)) {
      setNotiPanelVisible(false);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });


  return (

    <CHeader position="sticky" className="mb-4" style={{backgroundColor:'#252B3B'}}>

      <CContainer fluid className="header-primary" style={{backgroundColor:'#252B3B'}}>

      <div  style={{ width: 200, alignSelf: 'center' }} alt="" />      


        <div class="search-container" style={{ display: 'none' }}>
          <input type="text" class="search-input" placeholder="Search Intrucept" />
          <AiOutlineSearch class="search-icon" size={22} color="#00BDC1" />
        </div>

        <CHeaderNav className="ms-5" style={{ width: 400, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

          <CNavItem>
            <CNavLink href="#" style={{ textDecoration: 'none', display: 'flex', flexDirection: 'column' }}>
              <span className="header-welcome-text" style={{color:'white'}}>Welcome, {user ? user.firstName : ''} {user ? user.lastName : ''}</span>
              <span style={{color:'white', fontSize:13}}>{user?user.role:'Administrator'} </span>
              <span style={{color:'white', fontSize:11, }}>{user && user.organization?user.organization.name:''}</span>
            </CNavLink>
          </CNavItem>         

          <AppHeaderDropdown style={{ marginLeft: 50 }} />
        </CHeaderNav>

      </CContainer>
      

      {notiPanelVisible &&

        <div ref={myRef}
          style={{ position: 'absolute', top: 70, background: '#e50202', padding: 20, width: 400, maxHeight: 800, right: 40 }}>

          <>

            {last10Alerts.map((theAlert) => (


              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>{theAlert.vulnerability.vulnerabilityName}</span>
                <span style={{ color: 'white', fontSize: 13, fontWeight: 'bold',  }}>{theAlert.endpoint.host}</span>
                <span style={{ color: 'white', fontSize: 12, fontWeight: 'normal',  }}>{theAlert.endpoint.endpoint}</span>
                <span style={{ color: 'white', fontSize: 11, fontWeight: 'normal', marginTop: 5 }}>
                  {(new Date(theAlert.createdAt)).toLocaleDateString('en-US')} {(new Date(theAlert.createdAt)).toLocaleTimeString('en-US')}
                </span>
                <hr style={{ borderColor: 'white' }} />
              </div>

            ))}

          </>


        </div>
      }

    </CHeader>
  )
}

export default AppHeader
