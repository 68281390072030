import React from 'react';

const AzureDevOpsIntegration = () => {
  return (
    <div>
      <h3 className="darkText">Azure DevOps Integration Instructions for SAST and SCA Scans</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure Azure DevOps Pipeline Variables</h4>
      <ol>
        <li>Go to your Azure DevOps project.</li>
        <li>Navigate to <strong>Pipelines</strong> {'->'} <strong>Library</strong>.</li>
        <li>Create a new variable group or edit an existing one.</li>
        <li>Add the following variables:
          <ul>
            <li><strong>INTRUCEPT_CLIENT_ID</strong></li>
            <li><strong>INTRUCEPT_CLIENT_SECRET</strong></li>
            <li><strong>INTRUCEPT_APPLICATION_ID</strong></li>
            <li><strong>GITHUB_TOKEN</strong> (if deploying to GitHub)</li>
          </ul>
        </li>
        <li>Make sure to mark the secret variables as secure.</li>
      </ol>

      <h4 className="darkText">Step 2: Create Azure DevOps Pipeline</h4>
      <p>Create a new pipeline or edit an existing one with the following YAML content:</p>

      <pre>
        <code>
{`trigger:
  - master

pool:
  vmImage: 'ubuntu-latest'

variables:
  INTRUCEPT_CLIENT_ID: "<your_intrucept_client_id>"
  INTRUCEPT_CLIENT_SECRET: "<your_intrucept_client_secret>"
  INTRUCEPT_APPLICATION_ID: "<your_intrucept_application_id>"

steps:
- task: ArchiveFiles@2
  inputs:
    rootFolderOrFile: '$(System.DefaultWorkingDirectory)'
    includeRootFolder: false
    archiveType: 'zip'
    archiveFile: '$(Build.ArtifactStagingDirectory)/project.zip' 
    replaceExistingArchive: true
  displayName: 'Create project zip'

- script: |
    SAST_RESPONSE=$(curl -X POST \
      -H "Client-ID: $(INTRUCEPT_CLIENT_ID)" \
      -H "Client-Secret: $(INTRUCEPT_CLIENT_SECRET)" \
      -F "projectZipFile=@$(Build.ArtifactStagingDirectory)/project.zip" \
      -F "applicationId=$(INTRUCEPT_APPLICATION_ID)" \
      -F "scanName=SAST Scan from Azure Pipeline" \
      -F "language=javascript" \
      https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans)
    
    # Check for error in response.
    ERROR=$(echo "$SAST_RESPONSE" | jq -r '.error // empty')
    if [ ! -z "$ERROR" ]; then
       echo "Error: $ERROR"
       exit 1
    else
      VULNS_TABLE=$(echo "$SAST_RESPONSE" | jq -r '.vulnsTable')
      echo "Vulnerabilities Table:"
      echo "$VULNS_TABLE"
    fi 
  displayName: 'Perform SAST Scan'
  name: sastScan

- script: |
    SCA_RESPONSE=$(curl -X POST \
      -H "Client-ID: $(INTRUCEPT_CLIENT_ID)" \
      -H "Client-Secret: $(INTRUCEPT_CLIENT_SECRET)" \
      -F "projectZipFile=@$(Build.ArtifactStagingDirectory)/project.zip" \
      -F "applicationId=$(INTRUCEPT_APPLICATION_ID)" \
      -F "scanName=SCA Scan from Azure Pipeline" \
      -F "language=javascript" \
      https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans)
    
    # Check for error in response.
    ERROR=$(echo "$SCA_RESPONSE" | jq -r '.error // empty')
    if [ ! -z "$ERROR" ]; then
       echo "Error: $ERROR"
       exit 1
    else
      VULNS_TABLE=$(echo "$SCA_RESPONSE" | jq -r '.vulnsTable')
      echo "Vulnerabilities Table:"
      echo "$VULNS_TABLE"
    fi 
  displayName: 'Perform SCA Scan'
  name: scaScan

- script: |
    echo "SAST or SCA scan failed. Deployment cancelled."
    exit 1
  condition: and(succeeded(), or(ne(variables['sastScan.sastCanProceed'], 'true'), ne(variables['scaScan.scaCanProceed'], 'true')))
  displayName: 'Check SAST and SCA results'

- task: PublishBuildArtifacts@1
  inputs:
    pathToPublish: '$(Build.ArtifactStagingDirectory)/project.zip'
    artifactName: 'drop'
  displayName: 'Publish Artifact'
  condition: and(succeeded(), eq(variables['sastScan.sastCanProceed'], 'true'), eq(variables['scaScan.scaCanProceed'], 'true'))

- task: DownloadBuildArtifacts@0
  inputs:
    buildType: 'current'
    downloadType: 'single'
    artifactName: 'drop'
    downloadPath: '$(System.ArtifactsDirectory)'

- task: ExtractFiles@1
  inputs:
    archiveFilePatterns: '$(System.ArtifactsDirectory)/drop/project.zip'
    destinationFolder: '$(System.DefaultWorkingDirectory)/extracted'
    cleanDestinationFolder: true

- task: NodeTool@0
  inputs:
    versionSpec: '18'
  displayName: 'Install Node.js'

- script: |
    cd $(System.DefaultWorkingDirectory)/extracted
    npm ci
    npm run build
  displayName: 'npm install and build'

- task: Bash@3
  inputs:
    targetType: 'inline'
    script: |
      git config --global user.email "azurepipeline@example.com"
      git config --global user.name "Azure Pipeline"
      
      cd $(System.DefaultWorkingDirectory)/extracted
      
      git init
      git add .
      git commit -m "Deploy from Azure Pipelines"
      
      git remote add origin https://x-access-token:$(GITHUB_TOKEN)@github.com/ravi-intrucept/Vulnado_gradle.git
      git push -f origin HEAD:main
  env:
    GITHUB_TOKEN: $(GITHUB_TOKEN)
  displayName: 'Push to GitHub'
`}
        </code>
      </pre>

      <h4 className="darkText">Explanation of the Pipeline</h4>
      <p>This Azure DevOps pipeline does the following:</p>
      <ol>
        <li>Triggers on push to the main branch.</li>
        <li>Creates a zip file of the project for scanning.</li>
        <li>Performs SAST scan using the Intrucept API.</li>
        <li>Performs SCA scan using the Intrucept API.</li>
        <li>Displays the vulnerabilities tables from both scan results.</li>
        <li>Checks the scan results and fails the pipeline if issues are found.</li>
        <li>Publishes the artifact if scans pass.</li>
        <li>Builds the project using Node.js.</li>
        <li>Optionally deploys to GitHub (adjust as needed).</li>
      </ol>

      <p><strong>Note:</strong> Remember to replace placeholder 
      values for variables INTRUCEPT_CLIENT_ID, INTRUCEPT_CLIENT_SECRET, INTRUCEPT_APPLICATION_ID with
      your actual credentials.
       
       </p>
    </div>
  );
};

export default AzureDevOpsIntegration;