import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import '../style.css'; 
import { CgWebsite } from "react-icons/cg";
import { FaMobileAlt } from "react-icons/fa";
import { CiDesktop } from "react-icons/ci";
import { MdMiscellaneousServices } from "react-icons/md";
import { FaGlobe } from "react-icons/fa";


const ApplicationFindingsByType = () => {


  const [findings, setFindings] = useState({})
  const [onLoading, setOnLoading] = useState(false)

  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {

    setOnLoading(true);
  
    const token = localStorage.getItem('ASIToken');
    try {
      const response = await axios.get('/api/v1/users/organization-level-applications-findings-by-type', {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setFindings(response.data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      // Handle error appropriately, e.g., show an error message to the user
    } finally {
      setOnLoading(false);
    }
  };
 

  console.log('findings:', findings)

  return (
    <>

     

<div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '100%'
          }}>


            <span style={{ fontSize: 15 }}>APPLICATION FINDINGS BY TYPE</span>
            <hr style={{ borderColor: '#fff' }} />


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <CgWebsite size={50} style={{ color: 'rgb(115, 102, 255)' }} />

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(115, 102, 255)', fontSize: 25 }}>{findings.websites}</div>
                  <span style={{ fontSize: 13 }}>in <strong>Websites</strong></span>
                </div>


              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <FaMobileAlt size={50} style={{ color: '#FFA500' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: '#FFA500', fontSize: 25 }}>{findings.mobileApps}</div>
                  <span style={{ fontSize: 13 }}>in <strong>Mobile Applications</strong></span>
                </div>


              </div >

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <CiDesktop size={50} style={{ color: 'rgb(247, 49, 100)' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(247, 49, 100)', fontSize: 25 }}>{findings.desktopApps}</div>
                  <span style={{ fontSize: 13 }}>in <strong>Desktop Applications</strong></span>
                </div>


              </div >

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <FaGlobe size={50} style={{ color: 'rgb(61, 209, 181)' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(61, 209, 181)', fontSize: 25 }}>{findings.webServices?findings.webServices:0}</div>
                  <span style={{ fontSize: 13 }}>in <strong>Web Services</strong></span>
                </div>


              </div >

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <MdMiscellaneousServices size={50} style={{ color: 'rgb(161, 45, 210)' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(161, 45, 210)', fontSize: 25 }}>{findings.others}</div>
                  <span style={{ fontSize: 13 }}>in <strong>Others</strong></span>
                </div>


              </div >


            </div>



          </div>
    </>
  )
}

export default ApplicationFindingsByType