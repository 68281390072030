import React from 'react';

const DockerIntegration = () => {
  return (
    <div className="docker-integration">
      <h2 className="darkText">Docker Integration with SAST and SCA Scans</h2>
      <hr/>
      
      <section>
        <h3 className="darkText">Overview</h3>
        <p>This example explains how to integrate SAST (Static Application Security Testing) and SCA (Software Composition Analysis) scans into your Docker build process using external scripts. This approach allows for cleaner Dockerfiles and easier maintenance of scan scripts.</p>
      </section>

      <section>
        <h3 className="darkText">Step 1: Prepare Scan Scripts</h3>
        <p>Create two separate shell scripts for SAST and SCA scans. Place these in the same directory as your Dockerfile.</p>
        
        <h4 className="darkText">SAST Scan Script (sast-scan.sh)</h4>
        <pre>{`
#!/bin/sh

echo "Starting SAST scan..."
PROJECT_ZIP="project.zip"
SAST_API_URL="https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans"
CLIENT_ID="<your-intrucept-client-id>"
CLIENT_SECRET="<your-intrucept-client-secret>"
APPLICATION_ID="<your-intrucept-application-id>"
SCAN_NAME="Scan Name - Vuln App PHP"

echo "Creating project zip..."
zip -r $PROJECT_ZIP . -x "*.git*"

echo "Calling SAST API..."
RESPONSE=$(curl -X POST   -H "Client-ID: $CLIENT_ID"   -H "Client-Secret: $CLIENT_SECRET"   -F "projectZipFile=@$PROJECT_ZIP"   -F "applicationId=$APPLICATION_ID"   -F "scanName=$SCAN_NAME"   -F "language=$LANGUAGE"   $SAST_API_URL)

VULNS_TABLE=$(echo $RESPONSE | jq -r ".vulnsTable")

# Check if VULNS_TABLE is empty
if [ -z "$VULNS_TABLE" ]; then
  echo "No vulnerabilities found during SAST scan"
else
  echo "Vulnerabilities found during SAST Scan:"
  echo "$VULNS_TABLE"
fi


echo "SAST scan completed. Proceeding with further steps."
                                                            

        `}</pre>

        <h4 className="darkText">SCA Scan Script (sca-scan.sh)</h4>
        <pre>{`
#!/bin/sh

echo "Starting SCA scan..."
PROJECT_ZIP="project.zip"
SCA_API_URL="https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans"
CLIENT_ID="<your-intrucept-client-id>"
CLIENT_SECRET="<your-intrucept-client-secret>"
APPLICATION_ID="<your-intrucept-application-id>"
SCAN_NAME="Scan Name- Vuln app PHP"

echo "Creating project zip..."
zip -r $PROJECT_ZIP . -x "*.git*"

echo "Calling SCA API..."
RESPONSE=$(curl -X POST   -H "Client-ID: $CLIENT_ID"   -H "Client-Secret: $CLIENT_SECRET"   -F "projectZipFile=@$PROJECT_ZIP"   -F "applicationId=$APPLICATION_ID"   -F "scanName=$SCAN_NAME"   -F "language=$LANGUAGE"   $SCA_API_URL)

VULNS_TABLE=$(echo $RESPONSE | jq -r ".vulnsTable")

# Check if VULNS_TABLE is empty
if [ -z "$VULNS_TABLE" ]; then
  echo "No vulnerabilities found during SCA scan"
else
  echo "Vulnerabilities found during SCA scan:"
  echo "$VULNS_TABLE"
fi


echo "SCA scan completed. Proceeding with build."


        `}</pre>
      </section>

      <section>
        <h3 className="darkText">Step 2: Update Dockerfile</h3>
        <p>Modify your Dockerfile to include and run these scripts.
          <span style={{color:'red'}}> Make sure the build commands are updated as per the environment you are targetting. 
          The below example is for a Node JS project.</span>
        </p>
        <pre>{`
# Step 1: Use an official Node.js image as the base image
FROM node:18-alpine AS build

# Step 2: Set the working directory inside the container
WORKDIR /app

# Step 3: Install required tools for the SAST and SCA scans
RUN apk add --no-cache zip curl jq

# Step 4: Copy package.json and package-lock.json (if available)
COPY package*.json ./

# Step 5: Copy the scan scripts
COPY sast-scan.sh sca-scan.sh ./

# Step 6: Make the scripts executable
RUN chmod +x sast-scan.sh sca-scan.sh

# Step 7: Copy the entire project to the working directory
COPY . .

RUN mkdir -p /app/logs && echo "Directory /app/logs exists."


# Step 8: Run both SAST and SCA scan scripts, capturing all logs and debugging errors
RUN echo "Executing SAST scan..." \
    && ./sast-scan.sh 2>&1 | tee /app/logs/sast-scan.log || echo "SAST scan failed!" \
    && echo "SAST scan completed." \
    && echo "Executing SCA scan..." \
    && ./sca-scan.sh 2>&1 | tee /app/logs/sca-scan.log || echo "SCA scan failed!" \
    && echo "SCA scan completed." \
    && echo "Full logs:" \
    && cat /app/logs/sast-scan.log /app/logs/sca-scan.log

# Step 9: Install dependencies
RUN npm install

# Step 10: Build the React app for production
RUN npm run build

# Step 11: Use an official Nginx image to serve the built React app
FROM nginx:stable-alpine

# Step 12: Copy the build output to the Nginx server's web directory
COPY --from=build /app/build /usr/share/nginx/html

# Step 13: Expose the port the app will run on
EXPOSE 80

# Step 14: Start Nginx when the container runs
CMD ["nginx", "-g", "daemon off;"]
        `}</pre>
      </section>


      <section>
  <h3 className="darkText">Step 3: Build Docker Image</h3>
  <p>
    Assign right permissions to the scanner scripts:
  </p>
  <pre><code>chmod +x sast-scan.sh sca-scan.sh</code></pre>
  <p>
    To build the Docker image, you need to use the `docker build` command. This will compile the Dockerfile and create an image based on its instructions. Make sure you are in the project directory where your Dockerfile is located.
  </p>
  <p>
    Run the following command in your terminal:
  </p>
  <pre><code>docker build -t my-image-name:latest .</code></pre>
  <p>
    - Replace `my-image-name:latest` with your desired image name and tag. 
    - The `.` indicates that the build context is the current directory (where the Dockerfile is located).
  </p>
  <p>
    Once the build is complete, you can view your newly created image by running:
  </p>
  <pre><code>docker images</code></pre>
  <p>
    This will list all Docker images, and you should see the newly created image with your specified tag.
  </p>
</section>

<section>
  <h3 className="darkText">Step 4: View Scan Logs</h3>
  <p>
    After building the Docker image, you may have run security scans like Static Application Security Testing (SAST) or Software Composition Analysis (SCA) to analyze vulnerabilities within your code and dependencies.
  </p>
  <p>
    These scans generate logs that you can review to understand any vulnerabilities or security risks found in your application.
  </p>
  <p>
    To view the scan logs:
  </p>
  <ul>
    <li>Check the scan logs from the build process that were written to files. For example, the SAST and SCA scan logs are stored in the <code>/app/logs</code> directory inside the container.</li>
    <li>To view the logs, first, you need to run the Docker image and start a container. Use the following command to run the image:
      <pre><code>docker run -it &lt;image_name&gt; sh</code></pre>
      Replace <code>&lt;image_name&gt;</code> with the name of your Docker image. This will start a new container and give you access to an interactive shell.
    </li>
    <li>Once inside the container, use the <code>cat</code> command to view the logs. Run the following command:
      <pre><code>cat /app/logs/sast-scan.log /app/logs/sca-scan.log</code></pre>
    </li>
    
  </ul>
  
</section>




      <section>
        <h3 className="darkText">Explanation</h3>
        <ol>
          <li>We create two separate scripts (sast-scan.sh and sca-scan.sh) for SAST and SCA scans.</li>
          <li>These scripts are copied into the Docker image and made executable.</li>
          <li>The scripts are run before npm install, allowing us to catch potential security issues early.</li>
          <li>If either scan fails (canProceed is false), the Docker build will fail.</li>
          <li>This approach allows for easier maintenance and updating of the scan scripts without modifying the Dockerfile.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Usage</h3>
        <ol>
          <li>Place the sast-scan.sh and sca-scan.sh files in the same directory as your Dockerfile.</li>
          <li>Ensure the scripts have execute permissions (chmod +x sast-scan.sh sca-scan.sh).</li>
          <li>Build your Docker image as usual: docker build -t your-image-name .</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Note</h3>
        <p>Remember to replace the placeholder values (CLIENT_ID, CLIENT_SECRET, APPLICATION_ID) in the scan scripts with your actual Intrucept credentials.</p>
      </section>
    </div>
  );
};

export default DockerIntegration;