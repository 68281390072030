import React, { useState, useEffect } from "react";
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import noData from '../../../assets/nodata.png'

const UniqueFindingDistributionBySeverity = () => {

  const [severityData, setSeverityData] = useState({});
  const [riskRatingCounts, setRiskRatingCounts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
  setIsLoading(true);
  setError(null);
  
  try {
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('/api/v1/users/organization-level-unique-findings-distribution-by-severity', {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log('response.data:',response.data)
    
    setSeverityData(response.data.severityData);
    setRiskRatingCounts(response.data.riskRatingCounts);
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    setError("Failed to fetch data. Please try again later.");
  } finally {
    setIsLoading(false);
  }
};

  const chartSeries = [
    severityData.criticalCount || 0,
    severityData.highCount || 0,
    severityData.mediumCount || 0,
    severityData.lowCount || 0,
    severityData.infoCount || 0
  ];

  const chartOptions = {
    chart: {
      type: 'donut',
      events: {
        dataPointMouseEnter: function(event, chartContext, config) {
          
          // This prevents the default hover behavior
          event.preventDefault();
        }
      }
    },
    labels: ['Critical', 'High', 'Medium', 'Low', 'Info'],
    colors: ['#FF0000', '#A6001B', '#FFC300', '#B3FFB3', '#555'],
    legend: {
      position: 'bottom',
      formatter: function(seriesName, opts) {

        // Ensure that opts and opts.w are defined before accessing properties
        if (opts && opts.w && opts.w.globals && opts.w.globals.series) {
          const value = opts.w.globals.series[opts.seriesIndex];
          return seriesName + ": " + (value !== undefined ? value.toString() : '0');
        }
        return seriesName + ": 0";
      },
      onItemHover: {
        highlightDataSeries: false
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
          height: 200
        },
        legend: {
          position: 'bottom',
        }
      }
    }],
    plotOptions: {
      pie: {
        donut: {
          size: '70%'
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      enabled: false
    }
  };

  const isDataValid = chartSeries.some(value => value > 0);

  return (
    <div className="dashboardCard dashboardCardFull" style={{
      display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
      borderRadius: 10, padding: 20,
    }}>
      <span style={{ fontSize: 15 }}>UNIQUE FINDING DISTRIBUTION BY SEVERITY</span>
      <hr style={{ borderColor: '#fff' }} />

      {isDataValid ?

      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'45%', height:'500px'}}>
          <div style={{display:'flex', alignItems: 'center', marginTop:50}}>
            {isLoading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>{error}</div>
            ) : !isDataValid ? (
              <div>No data available</div>
            ) : (
              <ReactApexChart 
                options={chartOptions} 
                series={chartSeries} 
                type="donut" 
                height={'80%'}
                width={'80%'}
              />
            )}
          </div>
        </div>

        <div style={{width:'55%'}}>
          <table>
            <thead>
              <tr>
                <th style={{padding:10}}></th>
                <th style={{padding:10, textAlign:'center'}}>Application Assets</th>
              </tr>
            </thead>
            <tbody>
              {['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'].map((severity) => (
                <tr key={severity}>
                  <td style={{textAlign:'left'}}>
                    <div style={{
                      backgroundColor: severity === 'CRITICAL' ? '#FF0000' : 
                                       severity === 'HIGH' ? '#A6001B' : 
                                       severity === 'MEDIUM' ? '#FFC300' : '#B3FFB3',
                      padding: 5,
                      fontSize: 13,
                      borderRadius: 5,
                      textAlign: 'center',
                      color: severity === 'MEDIUM' || severity === 'LOW' ? '#000' : undefined
                    }}>
                      {severity}
                    </div>
                  </td>
                  <td style={{textAlign:'center'}}>{riskRatingCounts[severity] || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      :
      <div style={{display:'flex', flexDirection:'column', 
        justifyContent:'center', alignItems:'center'}}>
    <hr />
    <img src={noData} width={30} style={{marginTop: '10%'}}/>
    <span style={{ textAlign: 'center', fontSize:11 }}>No data available</span>
    
</div>

      }


    </div>
  );
};

export default UniqueFindingDistributionBySeverity;