import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { ShimmerTable } from "react-shimmer-effects";
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineSecurityScan } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";

const QuickScans = () => {

 const navigate = useNavigate()

 const [searchText, setSearchText] = useState('');
 const [filterList, setFilterList] = useState({});
 const [filterOptions, setFilterOptions] = useState({
   applications: [],
 });

 const [searchInput, setSearchInput] = useState('');
 const prevSearchInput = useRef(searchInput);

 const [sastScans, setSastScans] = useState([])
 const [onLoading, setOnLoading] = useState(false);  
 const [onDeleting, setOnDeleting] = useState(false);  

 const [scanToDelete, setScanToDelete] = useState(null);
 const [modalIsOpen, setModalIsOpen] = React.useState(false);

 const [page, setPage] = useState(0);
 const [count, setCount] = useState(0);
 const [rowsPerPage, setRowsPerPage] = useState(10);

 const customStyles = {
   content: {
     top: '30%',
     left: '25%',
     width: '50%',
     right: 'auto',
     bottom: 'auto',
     height: '25%',
     backgroundColor: '#ffffff',
     borderRadius: 15,
     borderColor: 'ffffff'
   },
 };

 const handleClick = (user) => {
   setScanToDelete(user);
   setModalIsOpen(true);
 };

 const handleConfirmation = (confirmed) => {
   if (confirmed) {
     deleteFunction(scanToDelete);
   }
   setModalIsOpen(false);
 };

 const deleteFunction = (scan) => {
   deleteScan(scan)
 };

 const closeModal = async () => {
   setModalIsOpen(false);
 };

 useEffect(() => {
   if (prevSearchInput.current && searchInput.length === 0) {
     fetchSASTScans(
       true,
       0,
       rowsPerPage,
       '',
       filterList
     );
   }
   prevSearchInput.current = searchInput;
 }, [searchInput]);

 const deleteScan = async (id) => {
   const bearerToken = localStorage.getItem('ASIToken');

   try {
     const response = await axios.delete(`api/v1/sast-scans/${id}`, {
       headers: {
         Authorization: `Bearer ${bearerToken}`,
       },
     });

     setOnDeleting(false);

     if (response.data.hasOwnProperty('error')) {
       toast.error(response.data.error, {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
       });

       setOnDeleting(false);
     } else {
       toast('Scan deleted', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
       });

       fetchSASTScans(true, page, rowsPerPage, searchText, filterList);
       setOnDeleting(false);
     }
   } catch (error) {
     console.error('Error:', error);
     setOnDeleting(false)
   }
 }

 const override: CSSProperties = {
   display: "block",
   margin: "0 auto",
   borderColor: "red",
 };

 const isFirstTime = useRef(true);

 const fetchSASTScans = async (isFirstTime, page, rowsPerPage, searchText, filterList) => {

   if (isFirstTime) {
     setOnLoading(true);
   }
 
   try {
     const queryParams = new URLSearchParams();
     
     if (searchText) {
       queryParams.append('search', searchText);
     }
     
     if (Object.keys(filterList).length > 0) {
       queryParams.append('filterList', JSON.stringify(filterList));
     }
     
     const queryString = queryParams.toString();
     const url = `/api/v1/sast-scans/all/${page}/${rowsPerPage}${queryString ? `?${queryString}` : ''}`;

     const token = localStorage.getItem('ASIToken');
     const response = await axios.get(url, {
       headers: { Authorization: `Bearer ${token}` },
     });
 
     setSastScans(response.data.sastScans);
     setCount(response.data.totalCount);
     setFilterOptions(response.data.filterOptions);
   } catch (error) {
     console.error('Error fetching SAST scans:', error);
   } finally {
     setOnLoading(false);
   }
 };


  const fetchAndUpdateStatus = async (scanId) => {

    console.log('')

    try {
      const token = localStorage.getItem("ASIToken");
      const response = await axios.post(
        `/api/v1/sast-scans/status-check`,
        { id: scanId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { status } = response.data; // Get status from API response


      // Update the specific scan in the state
      setSastScans((prevScans) =>
        prevScans.map((scan) =>
          scan._id === scanId ? { ...scan, status } : scan
        )
      );
    } catch (error) {
      console.error(`Error fetching status for scan ID ${scanId}:`, error);
    }
  };

  // Function to update statuses sequentially
  const fetchStatusesSequentially = async () => {

    console.log('comes here:')


    for (const scan of sastScans) {
      await fetchAndUpdateStatus(scan._id); // Sequentially call for each scan
    }
  };

  const latestParams = useRef({
    page: 0,
    rowsPerPage,
    searchText: '',
    filterList: {}
  });

  

    // Keep ref updated with the latest values of the parameters
    useEffect(() => {
      latestParams.current = { page, rowsPerPage, searchText, filterList };
    }, [page, rowsPerPage, searchText, filterList]);


    useEffect(() => {
      // Function to fetch using latest values from the ref
      const fetchUsingLatestParams = () => {
        const { page, rowsPerPage, searchText, filterList } = latestParams.current;
        console.log('Fetching with params:', { page, rowsPerPage, searchText, filterList });
        fetchSASTScans(false, page, rowsPerPage, searchText, filterList);
      };
  
      // Set interval to call fetchUsingLatestParams every 40 seconds
      const interval = setInterval(() => {
        fetchUsingLatestParams();
      }, 10000);
  
      // Initial fetch
      fetchSASTScans(true, 0, rowsPerPage, '', {});
      isFirstTime.current = false;
  
      return () => clearInterval(interval); // Cleanup interval on unmount
    }, []); // Empty dependency array



 const [user, setUser] = useState({});
 
 useEffect(() => {
   getUserDetails();
 }, []);

 const getUserDetails = () => {
   setUser(JSON.parse(localStorage.getItem('ASIUser')));
 }

 const goToViewReport = async (scanId) => {
   navigate('/sast-scan-detail?scanId=' + scanId);
 };

 const columns = [
   {
     name: '#',
     label: '#',
     options: {
       filter: false,
     }
   },
   {
     label: "Scan Name",
     options: {
       filter: false,
       download: true,
     }
   },   
   {
     label: "Application",
     options: {
       filter: true,
       download: true,
       filterOptions: {
         names: filterOptions.applications,
         logic: (value, filters) => {
           if (filters.length === 0) return false;
           return !filters.includes(value);
         }
       },
       filterList: filterList['Application'] || []
     }
   },
   {
     label: "Files Scanned",
     options: {
       filter: false,
       download: true,
     }
   },   
   {
     label: "Issue Count",
     options: {
       filter: false,
       download: true,
     }
   },   
   {
     label: "Started At",
     options: {
       filter: false,
       download: true,
     }
   },   
   {
     label: "Completed At",
     options: {
       filter: false,
       download: true,
     }
   },   
   {
     label: "Status",
     options: {
       filter: false,
       download: false,
       customBodyRender: (value, tableMeta, updateValue) => {
         return (
           <div style={{
             display: "flex",
             alignItems: "center"
           }} >
             {value.status && value.status == 'Completed' ? (
  <span
    style={{
      fontSize: 12,
      color: '#fff',
      fontWeight: 'normal',
      textAlign: 'center',
      backgroundColor: '#195905', // Green background for Completed
      padding: 5,
      paddingTop: 10,
      borderRadius: 5,
      width:110
    }}
  >
    {value.status && value.status.toUpperCase()}
  </span>
) : value.status == 'Failed' ? (
  <span
    style={{
      fontSize: 12,
      color: '#fff', // White text for Failed
      fontWeight: 'normal',
      textAlign: 'center',
      backgroundColor: '#ff0000', // Red background for Failed
      padding: 5,
      paddingTop: 10,
      borderRadius: 5,
      width:110
    }}
  >
    {value.status && value.status.toUpperCase()}
  </span>
) : (
  <span
    style={{
      fontSize: 12,
      color: '#000', // Black text for other statuses
      fontWeight: 'normal',
      textAlign: 'center',
      backgroundColor: '#ffbf00', // Yellow background for others
      padding: 5,
      paddingTop: 10,
      borderRadius: 5,
      width:110
    }}
  >
    {value.status && value.status.toUpperCase()}
  </span>
)}

           </div>
         )
       }
     }
   },
   {
     label: "View",
     options: {
       filter: false,
       download: false,
       customBodyRender: (value, tableMeta, updateValue) => {
         return (
           <div style={{
             display: "flex",
             alignItems: "center",
             justifyContent:'center'
           }} >
             {(value.scanCompletedAt) ?
               <CButton color="primary" variant="outline"
                 onClick={() => goToViewReport(value._id)}
                 className="m-2" style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color: '#fff', 
                 borderColor:'#fff', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center' }}>
                   View Report
               </CButton>
               :
               <span style={{ fontSize: 15, color: '#fff', fontWeight: 'bold' }}>---</span>
             }
           </div>
         )
       }
     }
   },
   {
     label: "Actions",
     options: {
       filter: false,
       download: false,
       customBodyRender: (value, tableMeta, updateValue) => {
         return (
           <div style={{
             display: "flex",
             alignItems: "center"
           }} >
             <>
               {(user.role == "Administrator" || user.role == "Application Manager") &&
                 <CButton color="danger"
                   onClick={() => handleClick(value)}
                   variant="outline"
                   className="m-1"
                   style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red', display:'flex', flexDirection:'row',
                    alignItems:'center', justifyContent:'center' }}>
                   <MdDeleteOutline style={{color:'red', marginRight:5}} size={15}/>Delete
                 </CButton>
               }
             </>
           </div>
         )
       }
     }
   },
 ];

 const getMuiTheme = () => createTheme({
   components: {
     MUIDataTableBodyCell: {
       styleOverrides: {
         root: {
           textAlign: "left",
           '&:nth-child(1)': {
             width: 30,
           },
         }
       }
     },
     MUIDataTableHeadCell: {
       styleOverrides: {
         root: {
           textAlign: "left",
           '&:nth-child(1)': {
             width: 30,
           },
         }
       }
     },
   }
 })
 const options = {
  filterType: "multiselect",
  responsive: "stacked",
  elevation: 0,
  filter: true,
  download: true,
  print: false,
  search: true,
  downloadOptions: {
    filename: 'SAST_Scans.csv', 
    separator: ',', 
  },
  searchOpen: true,
  viewColumns: true,
  selectableRows: false,
  rowsPerPageOptions: [10, 20, 60, 100, 150],
  customSearchRender: (searchText, handleSearch, hideSearch, options) => {
    return (
      <div style={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: '10px',
        padding: '8px 16px'
      }}>
        <input
          type="text"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setSearchText(searchInput);
              setPage(0);
              fetchSASTScans(
                true,
                0,
                rowsPerPage,
                searchInput,
                filterList
              );
            }
          }}
          placeholder="Search scans..."
          style={{
            padding: '8px 12px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            width: '300px',
            fontSize: '14px',
            color: '#000',
          }}
        />
        <button
          onClick={() => {
            setSearchText(searchInput);
            setPage(0);
            fetchSASTScans(
              true,
              0,
              rowsPerPage,
              searchInput,
              filterList
            );
          }}
          style={{
            padding: '8px 16px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#1976d2',
            color: 'white',
            cursor: 'pointer',
            fontSize: '14px'
          }}
        >
          Search
        </button>
        {searchInput && (
          <button
            onClick={() => {
              setSearchInput('');
              setSearchText('');
              setPage(0);
              fetchSASTScans(
                true,
                0,
                rowsPerPage,
                '',
                filterList
              );
            }}
            style={{
              padding: '8px 16px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#d32f2f',
              color: 'white',
              cursor: 'pointer',
              fontSize: '14px'
            }}
          >
            Clear
          </button>
        )}
      </div>
    );
  },
  onTableChange: (action, tableState) => {
    switch (action) {
      case 'changePage':
        setPage(tableState.page);
        fetchSASTScans(
          true,
          tableState.page,
          tableState.rowsPerPage,
          searchText,
          filterList
        );
        break;

      case 'changeRowsPerPage':
        setRowsPerPage(tableState.rowsPerPage);
        setPage(0);
        fetchSASTScans(
          true,
          0,
          tableState.rowsPerPage,
          searchText,
          filterList
        );
        break;

      case 'filterChange':
        const newFilterList = {};
        tableState.filterList.forEach((filter, index) => {
          if (filter && filter.length) {
            newFilterList[columns[index].label] = filter;
          }
        });
        setFilterList(newFilterList);
        setPage(0);
        fetchSASTScans(
          true,
          0,
          tableState.rowsPerPage,
          searchText,
          newFilterList
        );
        break;

      case 'resetFilters':
        setFilterList({});
        setPage(0);
        fetchSASTScans(
          true,
          0,
          tableState.rowsPerPage,
          searchText,
          {}
        );
        break;
    }
  },
  textLabels: {
    body: {
      noMatch: onLoading ? 'Loading data...' : 'No scans created yet',
    }
  },
  
  serverSide: true,
  count: count,
  page: page,
  rowsPerPage: rowsPerPage
};

var tableData = [];

for (var i = 0; i < sastScans.length; i++) {
  var dataItem = [];

  dataItem.push(((page) * 10) + (i+1));
  dataItem.push(sastScans[i].scanName);
  dataItem.push(sastScans[i].application.name);
  
  dataItem.push(sastScans[i].filesScanned?sastScans[i].filesScanned.length:0);
  dataItem.push(sastScans[i].issueCount);

  dataItem.push((new Date(sastScans[i].createdAt)).toLocaleDateString('en-US') + '\n' + (new Date(sastScans[i].createdAt)).toLocaleTimeString('en-US'));

  if (sastScans[i].scanCompletedAt) {
    dataItem.push((new Date(sastScans[i].scanCompletedAt)).toLocaleDateString('en-US')
      + '\n' + (new Date(sastScans[i].scanCompletedAt)).toLocaleTimeString('en-US'));
  } else {
    dataItem.push('---');
  }

  dataItem.push(sastScans[i]); // for status
  dataItem.push(sastScans[i]); // for view report link
  dataItem.push(sastScans[i]._id); // for delete

  tableData.push(dataItem);
}

const goToStartQuickScan = (e) => {
  e.preventDefault();
  navigate('/start-sast-scan')
}
return (
  <div className="activeScans">
    {setModalIsOpen && (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Remediations"
      >
        <text style={{ color: '#000', fontSize: 18 }}>Are you sure you want to permanently delete this scan?</text>
        <br/><br/>
        <button onClick={() => handleConfirmation(true)} style={{ width: 100, borderWidth: 0, backgroundColor: 'green', color:'white', padding: 10 }}>Yes</button>
        <button onClick={() => handleConfirmation(false)} style={{ marginLeft: 30, borderWidth: 0, width: 100, backgroundColor: 'red', color:'white', padding: 10 }}>No</button>
      </Modal>
    )}

    <div style={{ width: '100%', overflowX:'hidden' }}>
      <div>
        <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <h4><strong>SAST Scans</strong></h4>

          <CButton
            style={{
              width: 200,
              marginBottom: '2%',
              borderWidth: 0,
              fontSize: 13,
              fontStyle:'bold',
              background: '#e50202'
            }}
            onClick={goToStartQuickScan}
            color="primary"
            className="px-3"
          >
            <AiOutlineSecurityScan size={25} color='white'/>
            <span style={{marginLeft:10}}>Start a SAST Scan</span>
          </CButton>
        </div>

        {onLoading &&
          <ShimmerTable row={8} col={10} />
        }

        {!onLoading &&
          <>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                style={{ height: "57vh" }}
                data={tableData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </>
        }
      </div>
    </div>
  </div>
)
}

export default QuickScans