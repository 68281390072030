import React, { useState, useEffect } from "react";
import axios from "axios";
import "../style.css";
import Chart from "react-apexcharts";
import noData from "../../../assets/nodata.png";

const CWETop25MostDangerous = () => {
  const [result, setResult] = useState({});
  const [onLoading, setOnLoading] = useState(false);

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    setOnLoading(true);

    try {
      const token = localStorage.getItem("ASIToken");
      const response = await axios.get(
        "/api/v1/users/organization-level-findings-distribution-cwe-top-25",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setResult(response.data);
    } catch (error) {
      console.error("Error fetching CWE Top 25 data:", error);
    } finally {
      setOnLoading(false);
    }
  };

  const cweLabels = Object.keys(result);
  const cweValues = Object.values(result);

  const isDataValid = cweValues.some((value) => value > 0);

  const chartOptions = {
    chart: {
        type: "bar",
        height: 400,
        toolbar: {
            show: true,
        },
    },
    plotOptions: {
        bar: {
            horizontal: true,
            distributed: true,
            columnWidth: "50%",
        },
    },
    dataLabels: {
        enabled: true,
    },
    tooltip: {
        enabled: false,
        shared: false, // Ensures it does not group values from multiple series
        y: {
            formatter: (value, { dataPointIndex }) =>
                `${cweLabels[dataPointIndex]}: ${value}`,
        },
    },
    xaxis: {
        categories: cweLabels,
        labels: {
            show: true,
        },
    },
    yaxis: {
        labels: {
            show: true,
        },
    },
    colors: [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#546E7A",
        "#26a69a",
        "#D10CE8",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#546E7A",
        "#26a69a",
        "#D10CE8",
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#546E7A",
        "#26a69a",
        "#D10CE8",
    ],
};

  const series = [
    {
      data: cweValues,
    },
  ];

  return (
    <>
      <div
        className="dashboardCard dashboardCardFull"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          borderRadius: 10,
          padding: 20,
          height: 800,
        }}
      >
        <span style={{ fontSize: 15 }}>
          CWE TOP 25 MOST DANGEROUS SOFTWARE ERRORS
        </span>
        <hr style={{ borderColor: "#fff" }} />

        <Chart options={chartOptions} series={series} type="bar" height="90%" />
      </div>
    </>
  );
};

export default CWETop25MostDangerous;
