import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import '../style.css'; 
import { Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";

const Counters = () => {
  const [dashboardData, setDashboardData] = useState({})
  const [onLoading, setOnLoading] = useState(false)
  const ref = useRef(null);

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = async () => {
    setOnLoading(true);
    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get('/api/v1/users/dashboard', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDashboardData(response.data.data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setOnLoading(false);
    }
  };

  const openOpenTickets = async () => {

    window.location.href='/tickets?status=open'

  }

  const openResolvedTickets = async () => {

    window.location.href='/tickets?status=resolved'

  }

  return (
    <>    
      <div className="dashboardCard" style={{
        display: 'flex', flexDirection: 'row', backgroundColor: '#fff',
        borderRadius: 10, padding: 20,
      }}>
        <Link to="/users" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <FaUsers size={40} style={{ color: '#4CAF50' }} />
        </Link>
        <Link to="/users" style={{ textDecoration: 'none' }}>
          <div className="dashboardLinkDiv">
            {onLoading ?
              <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                <Breathing width={100} height={5} />
                <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                <Breathing width={50} height={5} style={{ marginTop: 3 }} />
              </div>
              :
              <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.usersCount}</h3>
            }
            <span style={{ fontSize: 16, color: '#4CAF50', }}>Users</span>
          </div>
        </Link>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, }}>
        <Link to="/asset-groups" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <FaObjectGroup size={40} style={{ color: '#2196F3' }} />
        </Link>
        <Link to="/asset-groups" style={{ textDecoration: 'none' }}>
          <div className="dashboardLinkDiv">
            {onLoading ?
              <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                <Breathing width={100} height={5} />
                <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                <Breathing width={50} height={5} style={{ marginTop: 3 }} />
              </div>
              :
              <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.assetGroupsCount}</h3>
            }
            <span style={{ fontSize: 16, color: '#2196F3', }}>Asset Groups</span>
          </div>
        </Link>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, }}>
        <Link to="/business-units" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IoIosBusiness size={40} style={{ color: '#9C27B0' }} />
        </Link>
        <Link to="/business-units" style={{ textDecoration: 'none' }}>
          <div className="dashboardLinkDiv">
            {onLoading ?
              <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                <Breathing width={100} height={5} />
                <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                <Breathing width={50} height={5} style={{ marginTop: 3 }} />
              </div>
              :
              <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.businessUnitsCount}</h3>
            }
            <span style={{ fontSize: 16, color: '#9C27B0', }}>Business Units</span>
          </div>
        </Link>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, }}>
        <Link to="/applications" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MdApps size={40} style={{ color: '#FF5722' }} />
        </Link>
        <Link to="/applications" style={{ textDecoration: 'none', width: '50%', }}>
          <div className="dashboardLinkDiv">
            {onLoading ?
              <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                <Breathing width={100} height={5} />
                <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                <Breathing width={50} height={5} style={{ marginTop: 3 }} />
              </div>
              :
              <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.applicationsCount}</h3>
            }
            <span style={{ fontSize: 16, color: '#FF5722', }}>Applications</span>
          </div>
        </Link>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>
          <AiOutlineSecurityScan size={40} style={{ color: '#3DD1B5' }} />
          <div className="dashboardLinkDiv">
            {onLoading ?
              <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                <Breathing width={100} height={5} />
                <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                <Breathing width={50} height={5} style={{ marginTop: 3 }} />
              </div>
              :
              <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.sastScansCount+
                dashboardData.scaScansCount+dashboardData.dastScansCount}</h3>
            }
            <span style={{ fontSize: 16, color: '#3F51B5', }}>Total Scans</span>
          </div>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>
        <Link to="/sast-scans" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AiOutlineSecurityScan size={40} style={{ color: '#3F51B5' }} />
        </Link>
        <Link to="/sast-scans" style={{ textDecoration: 'none' }}>
          <div className="dashboardLinkDiv">
            {onLoading ?
              <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                <Breathing width={100} height={5} />
                <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                <Breathing width={50} height={5} style={{ marginTop: 3 }} />
              </div>
              :
              <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.sastScansCount}</h3>
            }
            <span style={{ fontSize: 16, color: '#3F51B5', }}>SAST Scans</span>
          </div>
        </Link>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>
        <Link to="/sca-scans" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AiOutlineSecurityScan size={40} style={{ color: '#009688' }} />
        </Link>
        <Link to="/sca-scans" style={{ textDecoration: 'none' }}>
          <div className="dashboardLinkDiv">
            {onLoading ?
              <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                <Breathing width={100} height={5} />
                <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                <Breathing width={50} height={5} style={{ marginTop: 3 }} />
              </div>
              :
              <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.scaScansCount}</h3>
            }
            <span style={{ fontSize: 16, color: '#009688', }}>SCA Scans</span>
          </div>
        </Link>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>
        <Link to="/dast-scans" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AiOutlineSecurityScan size={40} style={{ color: '#673AB7' }} />
        </Link>
        <Link to="/dast-scans" style={{ textDecoration: 'none' }}>
          <div className="dashboardLinkDiv">
            {onLoading ?
              <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                <Breathing width={100} height={5} />
                <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                <Breathing width={50} height={5} style={{ marginTop: 3 }} />
              </div>
              :
              <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.dastScansCount}</h3>
            }
            <span style={{ fontSize: 16, color: '#673AB7', }}>DAST Scans</span>
          </div>
        </Link>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>
        <div style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MdErrorOutline size={40} style={{ color: '#E91E63' }} />
        </div>
        <div className="dashboardLinkDiv">
          {onLoading ?
            <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
              <Breathing width={100} height={5} />
              <Breathing width={70} height={5} style={{ marginTop: 3 }} />
              <Breathing width={50} height={5} style={{ marginTop: 3 }} />
            </div>
            :
            <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.issuesCount}</h3>
          }
          <span style={{ fontSize: 16, color: '#E91E63', }}>Total Vulnerabilities</span>
        </div>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>
        <div style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MdErrorOutline size={40} style={{ color: '#F44336' }} />
        </div>
        <div className="dashboardLinkDiv">
          {onLoading ?
            <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
              <Breathing width={100} height={5} />
              <Breathing width={70} height={5} style={{ marginTop: 3 }} />
              <Breathing width={50} height={5} style={{ marginTop: 3 }} />
            </div>
            :
            <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.sastVulns}</h3>
          }
          <span style={{ fontSize: 16, color: '#F44336', }}>SAST Vulnerabilities</span>
        </div>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>
        <div style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MdErrorOutline size={40} style={{ color: '#A3826C' }} />
        </div>
        <div className="dashboardLinkDiv">
          {onLoading ?
            <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
              <Breathing width={100} height={5} />
              <Breathing width={70} height={5} style={{marginTop: 3 }} />
              <Breathing width={50} height={5} style={{ marginTop: 3 }} />
            </div>
            :
            <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.scaVulns}</h3>
          }
          <span style={{ fontSize: 16, color: '#A3826C', }}>SCA Vulnerabilities</span>
        </div>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>
        <div style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MdErrorOutline size={40} style={{ color: '#FF9800' }} />
        </div>
        <div className="dashboardLinkDiv">
          {onLoading ?
            <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
              <Breathing width={100} height={5} />
              <Breathing width={70} height={5} style={{ marginTop: 3 }} />
              <Breathing width={50} height={5} style={{ marginTop: 3 }} />
            </div>
            :
            <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.dastVulns}</h3>
          }
          <span style={{ fontSize: 16, color: '#FF9800', }}>DAST Vulnerabilities</span>
        </div>
      </div>

     

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>
        <Link onClick={openOpenTickets} style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SiHelpdesk size={40} style={{ color: '#8A9A9D' }} />
        </Link>
        <Link onClick={openOpenTickets} style={{ textDecoration: 'none' }}>
          <div className="dashboardLinkDiv">
            {onLoading ?
              <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                <Breathing width={100} height={5} />
                <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                <Breathing width={50} height={5} style={{ marginTop: 3 }} />
              </div>
              :
              <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.openTicketsCount}</h3>
            }
            <span style={{ fontSize: 16, color: '#8A9A9D', }}>Open Tickets</span>
          </div>
        </Link>
      </div>

      <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>
        <Link onClick={openResolvedTickets} style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SiHelpdesk size={40} style={{ color: '#8BC34A' }} />
        </Link>
        <Link onClick={openResolvedTickets} style={{ textDecoration: 'none' }}>
          <div className="dashboardLinkDiv">
            {onLoading ?
              <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                <Breathing width={100} height={5} />
                <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                <Breathing width={50} height={5} style={{ marginTop: 3 }} />
              </div>
              :
              <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.closedTicketsCount}</h3>
            }
            <span style={{ fontSize: 16, color: '#8BC34A', }}>Resolved Tickets</span>
          </div>
        </Link>
      </div>

      <div className="" style={{ display: 'flex', flexDirection: 'row', width:'48%', 
         borderRadius: 10, padding: 20, marginTop: 30, background:'transparent' }}>
      </div>

    </>
  )
}

export default Counters