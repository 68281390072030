import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';
import { fontStyle } from '@mui/system';
import { getCountryCode, getCountryData, getCountryDataList, getEmojiFlag } from 'countries-list'


const Organizations = () => {

  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  const columns = [
    {
      name: 'index',
      label: '#',
      options: {
        filter: false,
        sort: true,         
      }
    },
    {
      name: 'name',
      label: 'Organization Name',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'email',
      label: 'Organization Email',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        filter: true,
        sort: true,
        filterType: 'multiselect',
        filterOptions: {
          renderValue: (value) => value
        },
        customBodyRender: (value) => {
          return (getCountryData(value)).name || 'N/A';
        }
      }
    },
    {
      name: 'primaryUser',
      label: 'Primary User',
      options: {
        filter: false,
        sort: true,
        display:true,
        download:false,
        customBodyRender: (value) => {
          if (!value) return '-';
          return `${value.firstName} ${value.lastName} (${value.email})`;
        }
      }
    },
    {
      name: 'primaryUserValue',
      label: 'Primary User',
      options: {
        filter: false,
        sort: true,
        display:false,
        download:true,
        
      }
    },
    {
      name: 'usersCount',
      label: 'Users',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'applicationsCount',
      label: 'Applications',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'issuesCount',
      label: 'Issues',
      options: {
        filter: false,
        sort: true,
      }
    },    
    {
      name: 'createdAt',
      label: 'Created Date',
      options: {
        filter: false,
        sort: true,        
      }
    },
    {
      name: '_id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        download:false,
        setCellHeaderProps: () => ({
          style: { textAlign: 'center', color:'#fff', fontWeight:'bold' }
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
              <IconButton
                size="small"
                onClick={() => handleView(value)}
                color="primary"
                title="View"
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleEdit(value)}
                color="primary"
                title="Edit"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleDeleteClick(organizations[tableMeta.rowIndex])}
                color="error"
                title="Delete"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        }
      }
    }
  ];

  const options = {
    filterType: 'multiselect',
    responsive: 'standard',
    selectableRows: 'none',
    download: true,
    print: false,
    search: true,
    filter: true,
    viewColumns: true,
    downloadOptions: {
      filename: 'Organizations.csv', 
      separator: ',', 
    },
    customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/add-organization')}
        style={{ marginRight: '20px', padding:'10px 10px 10px 10px', background:'#E50202', fontSize:13 }}
      >
        Add New Organization
      </Button>
    ),
    setFilterChipProps: (colIndex, colName, data) => {
      return {
        color: 'primary',
        variant: 'outlined',
        className: 'custom-filter-chip'
      };
    },
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    
    const bearerToken = localStorage.getItem('ASIToken');
    try {
      const response = await axios.get('api/v1/superadmin/organizations', {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      
      const orgsWithCountry = response.data.map((org, index) => ({
        ...org,
        country: org.country || 'N/A',
        primaryUserValue: org.primaryUser 
          ? `${org.primaryUser.firstName} ${org.primaryUser.lastName} (${org.primaryUser.email})` 
          : '-',
        createdAt: org.createdAt ? new Date(org.createdAt).toLocaleDateString() : 'N/A',
        index: index + 1 // Adding 1-based index
      }));
      
      

      setOrganizations(orgsWithCountry);

      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to fetch organizations');
      setLoading(false);
    }
  };

  const handleView = (orgId) => {
    navigate(`/view-organization?id=${orgId}`);
  };

  const handleEdit = (orgId) => {
    navigate(`/edit-organization?id=${orgId}`);
  };

  const handleDeleteClick = (org) => {
    setSelectedOrg(org);
    setDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    const bearerToken = localStorage.getItem('ASIToken');
    try {
      await axios.delete(`api/v1/superadmin/organizations/${selectedOrg._id}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      toast.success('Organization deleted successfully');
      fetchOrganizations();
      setDeleteDialog(false);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to delete organization');
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <h3>Organizations</h3>
      <br></br>
      <MUIDataTable
        title=""
        data={organizations}
        columns={columns}
        options={options}
      />

      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete Organization"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {selectedOrg?.name}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Organizations;